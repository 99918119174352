import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { ConstructionFilterModalComponent } from '@modules/constructions/components/construction-filter-modal/construction-filter-modal.component';
import { FilterService } from '@modules/filter/services/filter.service';

@Component({
  selector: 'packex-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterBarComponent {
  filterCategoryIsCollapsed = false;

  constructor(
    public filterService: FilterService,
    public breakpointObserverService: BreakpointObserverService,
    private readonly dialog: MatDialog,
  ) {}

  toggleFilterCategory() {
    if (
      this.breakpointObserverService.isMobile() ||
      this.breakpointObserverService.isTablet()
    ) {
      this.filterCategoryIsCollapsed = !this.filterCategoryIsCollapsed;
      document
        .getElementById('filter-bar-category-items')
        ?.classList.toggle('d-none');
    } else {
      this.filterCategoryIsCollapsed = false;
    }
  }

  public openModal(): void {
    if (this.breakpointObserverService.isMobile()) {
      this.dialog.open(ConstructionFilterModalComponent);
    }
  }
}
