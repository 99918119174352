import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@app/core/components/not-found/not-found.component';
import { CoreModule } from '@app/core/core.module';
import { UserCompletedGuard } from '@app/core/guards/user-completed.guard';
import { ROUTES } from '@app/routes';
import { RedirectToChangePasswordComponent } from '@modules/user/components/redirect-to-change-password.component';
import { VerifyEmailComponent } from '@modules/user/components/verify-email-component/verify-email-component';
import { RedirectGuard } from '@shared/guards/redirectGuard';
import { PackexModule } from '@shared/packex.module';
import { HomeRedirectComponent } from '@app/home-redirect.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeRedirectComponent,
    pathMatch: 'full',
  },
  {
    path: ROUTES.CART,
    loadChildren: () =>
      import('./modules/cart/cart.module').then((m) => m.CartModule),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: ROUTES.CONSTRUCTIONS,
    loadChildren: () =>
      import('./modules/constructions/constructions.module').then(
        (m) => m.ConstructionsModule,
      ),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: ROUTES.INVENTORY,
    loadChildren: () =>
      import('./modules/inventory/inventory.module').then(
        (m) => m.InventoryModule,
      ),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: ROUTES.USER,
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: ROUTES.ORDERS,
    loadChildren: () =>
      import('./modules/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: 'users/reset-password/:token',
    component: RedirectToChangePasswordComponent,
  },
  {
    path: 'users/verify/:token',
    component: VerifyEmailComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  { path: ROUTES.NOT_FOUND, component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  declarations: [NotFoundComponent],
  imports: [PackexModule, CoreModule, RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
