<packex-modal (onDismiss)="dismiss()">
  <h5 class="modal-title">PackEx weiterempfehlen</h5>

  <div class="modal-body">
    <p class="mb-4">Empfehle jetzt PackEx weiter und erhalte 1.000 Punkte nach der ersten Bestellung des eingeladenen Kunden</p>

    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="w-100 mb-3">
        <input matInput type="text" formControlName="email" [placeholder]="'USER.MODEL.EMAIL' | transloco" />
        <mat-error *ngIf="form.get('email')?.hasError('validateEmail')">{{'FORM.ERROR.EMAIL' | transloco}}</mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('exists')">
          <div [innerHtml]="'ORGANIZATION.INVITE_COLLEAGUES.EXISTS_ERROR' | transloco"></div>
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <packex-button [disabled]="form.invalid" (buttonClick)="submit()" class="w-auto">Jetzt einladen</packex-button>
</packex-modal>
