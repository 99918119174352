import { Component, effect, inject, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { LanguageService } from '@app/core/services/language.service';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DrawerService } from '@shared/services/drawer.service';
import { TitleService } from '@shared/services/title.service';
import { distinctUntilChanged, filter } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MatDialog } from '@angular/material/dialog';
import { CrossSellingDialogComponent } from '@shared/components/cross-selling-dialog/cross-selling-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { CartFlyoutComponent } from '@modules/cart/cart-flyout/cart-flyout.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { NgIf } from '@angular/common';
import Keycloak from 'keycloak-js';
import {
  KEYCLOAK_EVENT_SIGNAL,
  KeycloakEventType,
  ReadyArgs,
  typeEventArgs,
} from 'keycloak-angular';
import { environment } from '@env';
import { IMPERSONATE_KEY } from '@app/core/services/api.service';
import { updateStoredTokens } from './utils/keycloak-helper';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CartFlyoutComponent,
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    NgIf,
  ],
})
export class AppComponent implements OnInit {
  readonly storage = window.localStorage;
  private readonly keycloak = inject(Keycloak);
  private readonly keycloakSignal = inject(KEYCLOAK_EVENT_SIGNAL);

  constructor(
    private readonly drawerService: DrawerService,
    private readonly titleService: TitleService,
    private router: Router,
    private readonly languageService: LanguageService,
    private readonly translocoService: TranslocoService,
    private readonly gtmService: GoogleTagManagerService,
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cookieService: CookieService,
  ) {
    this.setupKeycloakSignalListener();
  }

  async ngOnInit() {
    if (environment.production) {
      this.gtmService.addGtmToDom().catch((err) => console.error(err));
    }

    this.checkForImpersonation();

    this.languageService.languageChanged$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((before, after) => {
          return Boolean(before && after && before !== after);
        }),
      )
      .subscribe(() => {
        window.location.reload();
      });

    this.translocoService.setActiveLang(this.languageService.currentLanguage());

    this.setupTitleListener();
    await this.showLoginBannerOnFirstLogin();
  }

  /**
   * Listens for Keycloak events and updates a token in local storage
   * once the user is authenticated or logs out.
   */
  private setupKeycloakSignalListener() {
    effect(() => {
      const keycloakEvent = this.keycloakSignal();

      if (keycloakEvent.type === KeycloakEventType.Ready) {
        if (keycloakEvent.args === true) {
          updateStoredTokens(this.keycloak);
        }
      }

      if (keycloakEvent.type === KeycloakEventType.AuthLogout) {
        updateStoredTokens(this.keycloak);
      }
    });
  }

  private setupTitleListener() {
    this.router.events
      .pipe(filter((e) => e instanceof ResolveEnd))
      .subscribe((e) => {
        const event = e as unknown as ResolveEnd;
        const { data } = this.getDeepestChildSnapshot(event.state.root);

        if (data?.['pageTitle']) {
          this.titleService.setTitle(data['pageTitle']);
        }
      });
  }

  get isOverlayVisible(): boolean {
    return this.drawerService.isDrawerVisible;
  }

  private getDeepestChildSnapshot(snapshot: ActivatedRouteSnapshot) {
    let deepestChild = snapshot.firstChild;
    while (deepestChild?.firstChild) {
      deepestChild = deepestChild.firstChild;
    }
    return deepestChild || snapshot;
  }

  private async showLoginBannerOnFirstLogin() {
    const isLoggedIn = await this.keycloak.authenticated;
    const hasSeenLoginBanner = localStorage.getItem('crossselling_login_seen');

    if (isLoggedIn && !hasSeenLoginBanner) {
      localStorage.setItem('crossselling_login_seen', 'true');
      this.dialog.open(CrossSellingDialogComponent);
    }
  }

  private checkForImpersonation(): void {
    const impersonate = window.location.hash.match(
      /impersonate=([A-Za-z0-9-]*)/,
    );

    if (impersonate) {
      sessionStorage.setItem(IMPERSONATE_KEY, impersonate[1]);
    }
  }
}
