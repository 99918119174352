import { Component, input } from '@angular/core';
import { LoyaltyStatus, LoyaltyStatusDto } from '../../loyalty-club.types';
import { CardComponent } from '@app/shared/components/ui/card/card.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { ClubStatusInfoComponent } from '../club-status-info/club-status-info.component';

@Component({
  selector: 'packex-status-overview',
  imports: [CardComponent, TranslocoPipe, ClubStatusInfoComponent],
  templateUrl: './status-overview.component.html',
  styleUrl: './status-overview.component.scss',
})
export class StatusOverviewComponent {
  status = input<LoyaltyStatusDto | null>();
  availableStatuses = input<LoyaltyStatus[] | null>();

  get statuses() {
    return this.status()?.availableStatuses || this.availableStatuses();
  }

  /**
   * Returns true if the given status is the current status or no status is set
   * @param status - The status to check
   * @returns
   */
  isCurrentStatus(status: LoyaltyStatus) {
    return (
      this.status() === undefined ||
      status.id === this.status()?.currentStatus.id
    );
  }
}
