import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import {
  CartOrder,
  Invoice,
  OrderItem,
  PagedCartOrderResult,
  PrintData,
} from '@shared/models/cart-order';
import { PageQuery } from '@shared/models/page-meta';
import { defaultOrderSort, SortOption } from '@shared/models/sort-option';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private readonly api: ApiService) {}

  public getOrders(
    query: PageQuery,
    sortOption: SortOption,
    search?: string,
  ): Observable<PagedCartOrderResult> {
    const params = new URLSearchParams({
      limit: String(query.limit),
      page: String(query.page),
      search: search || '',
      sort: sortOption?.sort || defaultOrderSort.sort,
      order: sortOption?.order || defaultOrderSort.order,
    });

    return this.api.get(`orders?${params.toString()}`);
  }

  public getOrderById(orderId: string): Observable<CartOrder> {
    return this.api.get(`orders/${orderId}`);
  }

  public getOrderItemById(orderItemId: string): Observable<OrderItem> {
    return this.api.get(`orders/items/${orderItemId}`);
  }

  public cancelOrder(orderId: string) {
    return this.api.delete(`orders/${orderId}`);
  }

  public cancelOrderItem(orderItemId: string | undefined) {
    return this.api.delete(`orders/items/${orderItemId}`);
  }

  public getFileUrl(
    orderItem: OrderItem,
    which: 'artwork' | 'dieline',
  ): string {
    return `${ApiService.apiUrl}/orders/items/${orderItem.id}/attachments/${which}`;
  }

  public getDielineSvg(orderItem: OrderItem) {
    return this.api.getRaw(
      `${ApiService.apiUrl}/inventory/${orderItem.references.inventoryItemId}/dieline?type=svg`,
      this.api.buildHttpOptions({
        contentType: 'application/svg',
        responseType: 'text',
      }),
    );
  }

  public getDownloadInvoiceUrl(invoice: Invoice) {
    return `${ApiService.apiUrl}/invoices/${invoice.id}/pdf`;
  }

  public approvePrintData(id: string | undefined) {
    return this.api.get(`print-data/${id}/approve`);
  }

  public rejectPrintData(id: string | undefined, message: string) {
    return this.api.post(`print-data/${id}/reject`, {
      message,
    });
  }

  public uploadPrintData(formData: FormData): Observable<PrintData> {
    const options = this.api.buildHttpOptions({
      responseType: 'json',
    });

    return this.api.post(`print-data`, formData, options);
  }

  public sendPrintData(printData: PrintData) {
    return this.api.get(`print-data/${printData.id}/send`);
  }
}
