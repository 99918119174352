import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ROUTES, USER_ROUTES } from '@app/routes';
import { OrganizationInviteWithStatusUpdatesModalComponent } from '@modules/organization/organization-invite-with-status-updates-modal/organization-invite-with-status-updates-modal.component';
import { User } from '@shared/models/user';
import { UserService } from '@app/core/services/user.service';
import { UserInfoComponent } from '@shared/components/ui/user-info/user-info.component';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal.pipe';

@Component({
  selector: 'packex-user-foldout',
  templateUrl: './user-foldout.component.html',
  styleUrls: ['./user-foldout.component.scss'],
  imports: [
    UserInfoComponent,
    RouterLink,
    ButtonComponent,
    TranslocoPipe,
    FormatDecimalPipe,
  ],
})
export class UserFoldoutComponent {
  @Input() user?: User | null;
  @Output() close = new EventEmitter();

  accountLink = `${ROUTES.USER}/${USER_ROUTES.ACCOUNT}`;
  organizationLink = `${ROUTES.USER}/${USER_ROUTES.ORGANIZATION}`;
  clubLink = `heldenclub`;

  private entered = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly usersService: UserService,
  ) {}

  @HostListener('mouseleave')
  public onMouseLeave() {
    if (this.entered) {
      this.close.emit();
    }
  }

  @HostListener('mouseover')
  public onMouseOver() {
    this.entered = true;
  }

  async logout() {
    await this.usersService.logout();
  }

  public inviteColleagues(): void {
    this.close.emit();

    this.dialog.open(OrganizationInviteWithStatusUpdatesModalComponent, {
      data: {
        title: 'ORGANIZATION.INVITE_COLLEAGUES.TITLE',
        description: 'ORGANIZATION.INVITE_COLLEAGUES.DESCRIPTION',
        settingsDescription:
          'ORGANIZATION.INVITE_COLLEAGUES.SETTINGS_DESCRIPTION',
        settingsHint: 'ORGANIZATION.INVITE_COLLEAGUES.SETTINGS_HINT',
        button: 'ORGANIZATION.INVITE_COLLEAGUES.BUTTON',
        invite: true,
      },
    });
  }
}
