import { Component, Inject, OnInit } from '@angular/core';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField } from '@angular/material/form-field';
import { TranslocoPipe } from '@jsverse/transloco';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { validateEmail } from '@app/utils/validators';
import { MatInput } from '@angular/material/input';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { LoyaltyClubService } from '@modules/club/loyalty-club.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'packex-refer-customer-dialog',
  imports: [
    ModalComponent,
    ReactiveFormsModule,
    ButtonComponent,
    MatCheckbox,
    MatError,
    MatFormField,
    TranslocoPipe,
    MatInput,
    NgIf,
  ],
  templateUrl: './refer-customer-dialog.component.html',
  styleUrl: './refer-customer-dialog.component.scss',
})
export class ReferCustomerDialogComponent
  extends ModalComponent
  implements OnInit
{
  form = new FormGroup({});

  constructor(
    dialogRef: MatDialogRef<ReferCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: any,
    private readonly snackbarService: SnackBarService,
    private readonly loyaltyService: LoyaltyClubService,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.form.addControl(
      'email',
      new FormControl(
        '',
        Validators.compose([validateEmail, Validators.required]),
      ),
    );
  }

  submit() {
    const email = this.form.get('email')?.value as unknown as string;
    this.loyaltyService.referCustomer(email).subscribe({
      next: () => {
        this.snackbarService.showSimpleSuccess(
          'Customer referred successfully',
        );
        this.dialogRef.close();
      },
      error: () => {
        this.snackbarService.showSimpleError('Failed to refer customer');
      },
    });
  }
}
