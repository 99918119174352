import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FilterService } from '@modules/filter/services/filter.service';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'packex-construction-filter-modal',
  templateUrl: './construction-filter-modal.component.html',
  styleUrls: ['./construction-filter-modal.component.scss'],
})
export class ConstructionFilterModalComponent extends ModalComponent {
  constructor(
    dialogRef: MatDialogRef<ConstructionFilterModalComponent>,
    public filterService: FilterService,
  ) {
    super(dialogRef);
  }
}
