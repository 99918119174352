import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  EXIT_INTENT_SESSION_KEY,
  ExitIntentModalService,
} from '@shared/services/exit-intent-modal.service';

@Component({
  selector: 'packex-exit-intent-modal-trigger',
  template: ` <div></div>`,
  styleUrls: ['exit-intent-modal-trigger.component.scss'],
})
export class ExitIntentModalTriggerComponent implements OnInit, OnDestroy {
  @Input() key!: EXIT_INTENT_SESSION_KEY;

  constructor(public exitIntentModalService: ExitIntentModalService) {}

  ngOnInit() {
    document.addEventListener('mouseout', this.handleMouseOut.bind(this));
  }

  ngOnDestroy() {
    document.removeEventListener('mouseout', this.handleMouseOut, true);
  }

  private handleMouseOut(e: MouseEvent) {
    const vpWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );

    // If the current mouse X position is within 50px of the right edge
    // of the viewport, return.
    if (e.clientX >= vpWidth - 50) return;

    // If the current mouse Y position is not within 50px of the top
    // edge of the viewport, return.
    if (e.clientY >= 10) return;

    this.exitIntentModalService.openIntentModal(this.key);
  }
}
