import { Component, HostBinding, input } from '@angular/core';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal.pipe';
import {
  LOYALTY_FEATURES,
  LoyaltyFeature,
  LoyaltyStatus,
} from '@modules/club/loyalty-club.types';
import clsx from 'clsx';
import { translate, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'packex-club-status-info',
  imports: [FormatDecimalPipe, TranslocoPipe],
  templateUrl: './club-status-info.component.html',
  styleUrl: './club-status-info.component.scss',
})
export class ClubStatusInfoComponent {
  status = input<LoyaltyStatus>();
  active = input<boolean>(false);

  @HostBinding('class')
  get hostClasses() {
    return {
      active: this.active(),
      [this.status()?.id ?? '']: true,
    };
  }

  featureNameFor(loyaltyFeature: LoyaltyFeature) {
    const feature = LOYALTY_FEATURES.find((f) => f.id === loyaltyFeature.id);

    const discount =
      this.status()?.discount == -1
        ? translate('LOYALTY.FEATURES.discount.individual')
        : (this.status()?.discount ?? 0) * 100.0 + '%';

    const count = loyaltyFeature.total ?? 0;
    return (
      (count ? `${count}x ` : '') +
      translate('LOYALTY.FEATURES.' + loyaltyFeature.id + '.title', {
        discount,
      })
    );
  }

  protected readonly clsx = clsx;
}
