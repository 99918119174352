import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import { SortOption } from '@shared/models/sort-option';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-list-sort',
  templateUrl: './list-sort.component.html',
})
export class ListSortComponent implements OnInit {
  currentSort = new BehaviorSubject<SortOption | null>(null);

  @ViewChild(PackexSelectComponent) select!: PackexSelectComponent<SortOption>;
  @Output() sortChanged = new EventEmitter<SortOption>();
  @Input() sortOptions: SortOption[] = [];

  ngOnInit() {
    const sortOption =
      this.sortOptions.find((sortOption) => sortOption.default) ||
      this.sortOptions[0];
    this.currentSort.next(sortOption);
  }

  currentChanged(value: SelectOption | null) {
    const sortOption = value as unknown as SortOption;
    this.currentSort.next(sortOption);
    this.sortChanged.emit(sortOption);
  }
}
