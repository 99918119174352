import { InventoryItem } from '@shared/models/inventory-item';
import { ProductOption } from '@shared/models/product-option';
import { User } from '@shared/models/user';

export type VariantStatus =
  | 'pending_artwork'
  | 'pending_preflight'
  | 'queued_for_preflight'
  | 'pending_user_approval'
  | 'pending_vendor_approval'
  | 'pending_manufacturer_approval'
  | 'preflight_error'
  | 'invalid'
  | 'complete';

export interface Variant {
  lastPreflightResult: {
    id: string;
    errors: string[];
    warnings: string[];
    infos: string[];
  };
  id: string;
  name: string;
  status: VariantStatus;
  artworkUrl: string;
  lastChangedBy: User;
  inventoryItem: InventoryItem;
  updatedAt: string;
  orderable: boolean;
  pendingServiceFromPreviousOrder: boolean;
  customerApprovalBy: User;
  manufacturerApprovalBy: User;
  blankArtwork: boolean;
  originalArtworkFilename: string;
  availableProductOptions: ProductOption[];
}
