<div class="container">
  <div class="details">
    <span class="title" [innerHTML]="title"></span><br />
    @if (feature()?.id === 'cad') {
    <packex-button
      class="cta"
      type="secondary"
      color="primary-dark"
      size="small"
      (buttonClick)="openCADialog()"
      >Jetzt Anfragen</packex-button
    >
    } @else {
    <span class="description">{{ description }}</span>
    }
  </div>
  @if (totalCredits) {
  <div class="right">
    <div class="credits">{{ remainingCredits }} / {{ totalCredits }}</div>
  </div>
  }
</div>
