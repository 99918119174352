import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { Configuration } from '@shared/models/configuration';
import { handleInputKeyDown } from '@app/utils/input.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'configurator-input',
  templateUrl: './configurator-input.component.html',
  styleUrls: ['./configurator-input.component.scss'],
})
export class ConfiguratorInputComponent implements OnInit {
  @Input() headline!: string;
  @Input() value!: number;
  @Input() unit!: string;
  @Input() min!: number;
  @Input() max!: number;
  @Input() disabled!: boolean;
  @Input() field!: keyof Configuration;
  @Input() error?: string | null;
  @Input() width = '100%';
  @Input() tabIndex: string | null = null;
  @Input() updateOnError = false;

  @Output() errorHandler = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<number>();
  @Output() beforeUpdate = new EventEmitter<void>();
  @Input() configurationUpdated!: Observable<Configuration | null>;
  @ViewChild('inputField') inputField!: ElementRef;
  debouncer: Subject<number> = new Subject<number>();

  previousValue = 0;

  ngOnInit(): void {
    this.debouncer
      .pipe(distinctUntilChanged(), debounceTime(750), untilDestroyed(this))
      .subscribe((value) => this.updateValue(value));

    this.configurationUpdated
      .pipe(untilDestroyed(this))
      .subscribe((configuration: Configuration | null) => {
        if (
          configuration &&
          this.value !== (configuration[this.field] as number)
        ) {
          this.value = configuration[this.field] as number;
        }
        this.previousValue = this.value;
        this.error = null;
      });
  }

  public onChange(): void {
    this.beforeUpdate.emit();
    this.debouncer.next(this.value);
  }

  public onInputKeyDown(event: any) {
    return handleInputKeyDown(event, this.inputField, true);
  }

  onBlur() {
    this.debouncer.next(this.value);
  }

  onFocus() {
    this.inputField.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    this.inputField.nativeElement.select();
  }

  updateValue(value: number): void {
    if (value === this.previousValue) {
      return;
    }

    this.error = null;
    this.value = Math.ceil(value);

    if (this.value < this.min) {
      this.error = `CONFIGURATION.ERRORS.${this.field.toUpperCase()}_FALL_BELOW`;
    } else if (this.value > this.max) {
      this.error = `CONFIGURATION.ERRORS.${this.field.toUpperCase()}_EXCEEDS`;
    }

    this.errorHandler.emit({ [this.field]: this.error });
    if (!this.error || this.updateOnError) {
      this.valueChanged.emit(this.value);
    }
  }
}
