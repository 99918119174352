<ng-container *transloco="let t">
  <div class="filter-cards">
    <div class="filter-card">
      <packex-filter-card
        image="assets/images/category-images/alle.png"
        [firstCard]="true"
        [title]="'CONSTRUCTIONS.CATEGORIES.ALL' | transloco"
        (click)="filterService.removeAllFilters()"
        [active]="filterService.selectedFilters.length === 0">
      </packex-filter-card>
    </div>
    <div
      class="filter-card"
      *ngFor="let filterCategory of filterCards">
      <packex-filter-card
        [image]="filterCategory.image || ''"
        [title]=" filterCategory.name | transloco"
        [active]="filterCategory.active"
        [backgroundColor]="filterCategory?.backgroundColor"
        (click)="applyFilter(filterCategory)">
      </packex-filter-card>
    </div>
  </div>
</ng-container>
