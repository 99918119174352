import { Component } from '@angular/core';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { MatDialog } from '@angular/material/dialog';
import { ReferCustomerDialogComponent } from '@modules/club/components/refer-customer-dialog/refer-customer-dialog.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'packex-invite-banner',
  templateUrl: './invite-banner.component.html',
  styleUrls: ['./invite-banner.component.scss'],
  imports: [ButtonComponent, TranslocoPipe],
})
export class InviteBannerComponent {
  constructor(private readonly dialog: MatDialog) {}

  inviteCustomers() {
    console.log('Invite customers');
    this.dialog.open(ReferCustomerDialogComponent);
  }
}
