<div class="filter-bar">
  <div class="filter-bar-header d-flex justify-content-between equal-height align-items-stretch p-3 ps-3 ps-md-0"
       (click)="openModal()">
    <div class="d-flex align-items-center">
      <packex-icon icon="filter" class="me-2"></packex-icon>
      <h5
        class="text-primary-dark m-0 d-flex justify-content-center align-items-center">{{ 'FILTER.HEADLINE' | transloco }}
        <span class="circle circle-small subtitle-2 bg-secondary-light ms-3" *ngIf="filterService.selectedFilters.length">{{ filterService.selectedFilters.length }}</span></h5>
    </div>

  </div>
  <div class="filter-bar-category-items d-none d-md-block" id="filter-bar-category-items">
    <packex-construction-filter-categories>
    </packex-construction-filter-categories>
    <div class="filter-bar-footer d-flex align-items-center p-3 ps-0">
      <packex-button icon="refresh" type="tertiary" (buttonClick)="filterService.removeAllFilters()"
                     buttonPadding="p-0"
                     color="primary-dark" class="w-auto">
        {{ 'FILTER.RESET' | transloco }}
      </packex-button>
    </div>
  </div>
</div>
