import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ROUTES } from '@app/routes';
import { RequestOfferModalComponent } from '@modules/constructions/components/request-offer-modal/request-offer-modal.component';
import { CONSTRUCTION } from '@modules/constructions/pages/constructions-page/constructions-page.component';
import { FilterType } from '@modules/filter/models/filter-category';
import { FilterItem } from '@modules/filter/models/filter-item';
import { FilterService } from '@modules/filter/services/filter.service';
import { translate, TranslocoPipe } from '@jsverse/transloco';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'packex-products-foldout',
    templateUrl: './products-foldout.component.html',
    styleUrls: ['./products-foldout.component.scss'],
    imports: [NgIf, NgFor, NgClass, TranslocoPipe]
})
export class ProductsFoldoutComponent {
  @Input() mobileView = false;
  @Output() filterClicked = new EventEmitter();
  @Output() close = new EventEmitter();

  private entered = false;

  constructor(
    private readonly filterService: FilterService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}

  @HostListener('mouseleave')
  public onMouseLeave() {
    if (this.entered) {
      this.close.emit();
    }
  }

  @HostListener('mouseover')
  public onMouseOver() {
    this.entered = true;
  }

  get categories() {
    return (
      this.filterService.filterCategories$
        .getValue()
        .find((x) => x.type === 'type')?.items || []
    );
  }

  get topics() {
    return (
      this.filterService.filterCategories$
        .getValue()
        .find((x) => x.type === 'topics')?.items || []
    );
  }

  public async addFilter(type: FilterType, item: FilterItem) {
    this.filterService.addFilter(item);

    await this.router.navigateByUrl(
      `${ROUTES['CONSTRUCTIONS']}/${type}/${item.type}`,
      {
        replaceUrl: true,
        onSameUrlNavigation: 'reload',
      },
    );
  }

  public openRequestConstructionModal(): void {
    this.dialog.open(RequestOfferModalComponent, {
      data: {
        type: CONSTRUCTION,
      },
    });
  }

  public openMusterboxLink(): void {
    window.open(translate('EXIT_INTENT.SAMPLE_BOX_LINK'), '_blank');
  }
}
