import { Component, ViewEncapsulation } from '@angular/core';
import { FilterService } from '@modules/filter/services/filter.service';

@Component({
  selector: 'packex-filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterChipComponent {
  constructor(public filterService: FilterService) {}
}
