<h4>{{ 'LOYALTY.PAGE.TRANSACTIONS.TITLE' | transloco }}</h4>
@if (transactions()) { @if (totalItems > 0) {
<mat-table
  class="items-list"
  [dataSource]="
    transactions()!.items | paginate : { itemsPerPage, currentPage, totalItems }
  "
  matSort
  matSortActive="createdAt"
  matSortDirection="desc"
>
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef>
      <span class="subtitle-2 text-primary-medium">{{
        'LOYALTY.PAGE.TRANSACTIONS.TYPE' | transloco
      }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <p class="my-3 my-md-0 text-break text-grey-dark">
        {{
          'LOYALTY.REASON.' + element.reason
            | transloco : { orderNumber: element.orderNumber }
        }}
      </p>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <mat-header-cell *matHeaderCellDef>
      <span class="subtitle-2 text-primary-medium">{{
        'LOYALTY.PAGE.TRANSACTIONS.RECEIVED_AT' | transloco
      }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <p class="my-3 my-md-0 text-break text-grey-dark">
        {{ element.createdAt | formatDate }}
      </p>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expiresAt">
    <mat-header-cell *matHeaderCellDef>
      <span class="subtitle-2 text-primary-medium">{{
        'LOYALTY.PAGE.TRANSACTIONS.EXPIRES_AT' | transloco
      }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <p class="my-3 my-md-0 text-break text-grey-dark">
        {{ element.expiresAt | formatDate }}
      </p>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>
      <span class="subtitle-2 text-primary-medium">{{
        'LOYALTY.PAGE.TRANSACTIONS.STATUS' | transloco
      }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <p class="my-3 my-md-0 text-break text-grey-dark">
        {{
          'LOYALTY.TRANSACTIONS.' + element.status
            | transloco : { orderNumber: element.orderNumber }
        }}
      </p>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="points">
    <mat-header-cell *matHeaderCellDef class="cell-right">
      <span class="subtitle-2 text-primary-medium">{{
        'LOYALTY.PAGE.TRANSACTIONS.POINTS' | transloco
      }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="cell-right">
      <div class="points">
        {{ element.points >= 0 ? '+' : '-'
        }}{{ element.points | formatDecimal }}
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<packex-pagination
  (paginationChanged)="onPageChanged($event)"
  [loading]="false"
  [itemsPerPageLabel]="'PAGINATION.PRODUCTS_PER_PAGE'"
  [itemsPerPage]="itemsPerPage"
  [totalItems]="totalItems"
  [currentPage]="currentPage"
>
  >
</packex-pagination>
} @else {
<p class="text-grey-dark">Noch keine Punkte erhalten.</p>
} }
