<div class="filter-bar-category-item" *ngFor="let category of filterService.filterCategories$ | async">
  <ng-container *ngIf="category.type === 'isPrivate'; else defaultTemplate">
    <div
      class="filter-bar-category-item-title d-flex align-items-center justify-content-between cursor-pointer px-0">
      <div class="d-inline-flex align-items-center">
        <span class="subtitle-1">{{ category.name | transloco }}</span>
      </div>
      <mat-slide-toggle color="accent"
                        [checked]="category.active" (click)="filterService.toggleCategoryActive(category.name)"></mat-slide-toggle>
    </div>
  </ng-container>
  <ng-template #defaultTemplate>
    <div
      class="filter-bar-category-item-title d-flex align-items-center justify-content-between cursor-pointer px-0 pe-md-2 ps-0"
      (click)="filterService.toggleCategoryActive(category.name)">
      <div class="d-inline-flex align-items-center">
        <span class="subtitle-1">{{ category.name | transloco }}</span>
        <span class="badge" *ngIf="filterService.countActiveCategoryItems(category.items)">
            {{ filterService.countActiveCategoryItems(category.items) }}
          </span></div>
      <packex-button type="clear-icon" class="d-none d-md-inline-flex me-2" [icon]="category.active ? 'substract' : 'add'"></packex-button>
    </div>
    <div
      class="filter-bar-category-item-content align-items-center flex-column px-0 pe-md-2 py-3 pt-0 {{ isCategoryOpen(category) ? 'd-flex' : 'd-none'}}">
      <div class="filter-bar-item w-100" *ngFor="let item of category.items">
        <div class="filter-bar-item-title d-flex align-items-center justify-content-between cursor-pointer"
             (click)="filterService.addFilter(item)">
          <p class="text-primary-dark text-break m-0">{{ item.name | transloco }}</p>
          <mat-checkbox (click)="$event.preventDefault()" [checked]="item.active"></mat-checkbox>
        </div>
      </div>
    </div>
  </ng-template>
</div>
