<div class="filter-chip-container d-flex justify-content-start flex-wrap" *ngIf="filterService.selectedFilters?.length">
  <mat-chip *ngFor="let filter of filterService.selectedFilters" (removed)="filterService.removeFilter(filter)" class="me-2 mb-2">
    {{ filter | transloco }}
    <button matChipRemove>
      <packex-icon icon="close_circle" color="primary"></packex-icon>
    </button>
  </mat-chip>

  <div *ngIf="filterService.selectedFilters.length > 0" (click)="filterService.removeAllFilters()"
       class="deactivate-all text-nowrap mb-2 d-flex align-items-center">{{ 'FILTER.REMOVE_ALL' | transloco }}</div>
</div>
