import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartProductComponent } from '@modules/cart/cart-product/cart-product.component';
import { CartService } from '@modules/cart/cart.service';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { DrawerService } from '@shared/services/drawer.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { CartItemThumbnailComponent } from '../cart-item-thumbnail/cart-item-thumbnail.component';
import { InventoryItemHeaderComponent } from '../../inventory/components/inventory-item-header/inventory-item-header.component';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { CartFlyoutItemComponent } from '../cart-flyout-item/cart-flyout-item.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-cart-flyout-product',
    templateUrl: './cart-flyout-product.component.html',
    styleUrls: ['./cart-flyout-product.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgClass, CartItemThumbnailComponent, InventoryItemHeaderComponent, ButtonComponent, NgFor, CartFlyoutItemComponent, TranslocoPipe]
})
export class CartFlyoutProductComponent extends CartProductComponent {
  constructor(
    dialog: MatDialog,
    modalCartService: ModalCartService,
    drawerService: DrawerService,
    cartService: CartService,
    gtm: GoogleTagService,
  ) {
    super(dialog, modalCartService, drawerService, cartService, gtm);
  }
}
