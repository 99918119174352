<div class="slider-card py-2 clearfix" [matTooltip]="'CONSTRUCTIONS.FORMAT_DISABLED' | transloco"
     [matTooltipDisabled]="!disabled">
  <div class="d-flex">
    <h5 class="slider-headline">{{ headline | transloco }}</h5>
    <div class="d-flex flex-fill justify-content-center">
      <packex-button type="clear-icon" icon="substract" (buttonClick)="substract()" iconSize="m" color="secondary"
                     [attr.data-testid]="'configurator-field-'+ field + '-subtract'"
                     class="me-3" [disabled]="disabled">
      </packex-button>
      <div class="d-flex align-items-center">
        <mat-form-field appearance="outline" color="accent" class="no-subscription" [ngClass]="{'text-error error': error}">
          <input
            matInput
            type="number"
            [attr.data-testid]="'configurator-field-'+ field + '-input'"
            (blur)="onInputBlur()"
            (keydown)="onInputKeyDown($event)"
            [(ngModel)]="value"
            [min]="min"
            [max]="max"
            [step]="step"
            class="text-center caption"
            autocomplete="off"
            [disabled]="disabled"
            #sliderInput>
        </mat-form-field>
        <span class="ms-2 caption"> {{ unit }}</span>
      </div>
      <packex-button type="clear-icon" icon="add" (buttonClick)="add()" iconSize="m" color="secondary" class="ms-3"
                     [attr.data-testid]="'configurator-field-'+ field + '-add'"
                     [disabled]="disabled"></packex-button>
    </div>
  </div>
  <div class="config-slider-container">
    <mat-slider [min]="min" [max]="max" [step]="step" color="accent" [disabled]="disabled">
      <input matSliderThumb [value]="value" (change)="onChange($event)"/>
    </mat-slider>
    <div class="min-max-labels d-flex justify-content-between">
      <div class="caption text-primary-medium">{{ min }} {{ unit }}</div>
      <div class="text-error" *ngIf="error">{{ error | transloco }}</div>
      <div class="caption text-primary-medium">{{ max }} {{ unit }}</div>
    </div>
  </div>
</div>
