<div class="user-foldout bg-white">
  <div class="ps-6 p-3 border-bottom border-grey-medium">
    <packex-user-info [user]="user" [showEmail]="true"></packex-user-info>
  </div>
  <div class="user-menu border-bottom border-grey-medium">
    <ul>
      <li
        class="ps-6 py-2 d-flex align-items-center cursor-pointer"
        [routerLink]="accountLink"
        [attr.data-testid]="'account-link'"
      >
        {{ 'USER.MY_ACCOUNT' | transloco }}
      </li>
      @if (user?.loyaltyStatus?.disabled === false) {
      <li
        class="ps-6 pe-6 py-2 d-flex align-items-center cursor-pointer auto"
        [routerLink]="clubLink"
        [attr.data-testid]="'organization-link'"
      >
        <div class="status">
          <div>{{ 'LOYALTY.MENU_TITLE' | transloco }}</div>
          <div class="statusDetails">
            <div class="statusName">
              {{ user?.loyaltyStatus?.currentStatus?.name }}
            </div>
            <div class="statusPoints">
              <span class="currentPoints">{{
                user?.loyaltyStatus?.points | formatDecimal
              }}</span
              >@if (user?.loyaltyStatus?.nextStatus) { /{{
                user?.loyaltyStatus?.nextStatus?.minPoints | formatDecimal
              }}
              } {{ 'LOYALTY.POINTS' | transloco }}
            </div>
          </div>
        </div>
      </li>
      }
      <li
        class="ps-6 py-2 d-flex align-items-center cursor-pointer"
        [routerLink]="organizationLink"
        [attr.data-testid]="'organization-link'"
      >
        {{ 'USER.ORGANIZATION.PAGE_TITLE' | transloco }}
      </li>
      <li
        class="ps-6 py-2 d-flex align-items-center cursor-pointer"
        (click)="logout()"
        [attr.data-testid]="'logout-link'"
      >
        {{ 'USER.LOGOUT' | transloco }}
      </li>
    </ul>
  </div>
  <div class="ps-6 p-2">
    <packex-button
      icon="add_circle"
      type="tertiary"
      color="primary-dark"
      (buttonClick)="inviteColleagues()"
      class="w-auto"
      >{{ 'USER.INVITE_COLLEAGUES' | transloco }}
    </packex-button>
  </div>
</div>
