import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ROUTES, USER_ROUTES } from '@app/routes';
import { User } from '@shared/models/user';
import { filter, map, of } from 'rxjs';
import { UserService } from '../services/user.service';
import Keycloak from 'keycloak-js';

/**
 * UserCompletedGuard
 *
 * This guard is used to check if the user has completed the registration
 * process and/or has any pending invitations.
 */
@Injectable({
  providedIn: 'root',
})
export class UserCompletedGuard implements CanActivate {
  private keycloak = inject(Keycloak);

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) {}

  canActivate() {
    if (this.keycloak.authenticated) {
      return this.checkForCompletedUser();
    } else {
      return of(true);
    }
  }

  private checkForCompletedUser() {
    return this.userService.user$.pipe(
      filter((user: User | null) => !!user),
      map((user: User | null) => {
        const invitation = this.userService.getPendingInvitation();

        // Check email verification, then invitations and finally user completion in this order
        if (user && !user.isEmailVerified) {
          this.router.navigateByUrl(`${ROUTES['USER']}/${USER_ROUTES.VERIFY}`);
          return false;
        } else if (invitation) {
          this.router.navigateByUrl(
            `${ROUTES['USER']}/invite/${invitation.id}`,
          );
          return false;
        } else if (!this.userService.userCompleted) {
          this.router.navigateByUrl(
            `${ROUTES['USER']}/${USER_ROUTES.USER_COMPLETE}`,
          );
          return false;
        }
        return true;
      }),
    );
  }
}
