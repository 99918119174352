import { Feature } from '@modules/club/components/club-feature/club-feature.component';

export interface LoyaltyTransaction {
  reason: string;
  points: number;
  status: string;
  orderItemNumber?: string;
  createdAt: Date;
  expiresAt: Date;
}

export interface LoyaltyFeatureStatus extends LoyaltyFeature {
  remaining: number;
}

export interface LoyaltyStatus {
  id: string;
  isDefault: boolean;
  name: string;
  minPoints: number;
  discount: number;
  features: LoyaltyFeature[];
}

export interface LoyaltyStatusWithDates extends LoyaltyStatus {
  enteredAt: Date;
  expiresAt: Date;
}

export interface LoyaltyStatusDto {
  disabled: boolean;
  points: number;
  pendingPoints: number;
  currentStatus: LoyaltyStatusWithDates;
  nextStatus?: LoyaltyStatus;
  nextLevelPoints: number;
  expiringPoints: number;
  discount: number;
  features: LoyaltyFeatureStatus[];
  availableStatuses: LoyaltyStatus[];
}

export interface LoyaltyFeature {
  id: string;
  total?: number;
}

export const LOYALTY_FEATURES: Feature[] = [
  {
    id: 'prepress_check',
    title: 'Premium-Druckdatencheck',
    description: 'kostenlos verfügbar',
  },
  {
    id: 'rework',
    title: 'Druckdaten-Aufbereitung',
    description: 'kostenlos verfügbar',
  },
  {
    id: 'fsc',
    title: 'FSC-Siegel',
    description: 'kostenlos verfügbar',
  },
  {
    id: 'quality_certificate',
    title: 'Qualitätszertifikat',
    description: 'kostenlos verfügbar',
  },
  {
    id: 'cad',
    title: 'CAD-Datenerstellung',
    description: 'kostenlos verfügbar',
  },
  {
    id: 'discount',
    title: 'x% Rabatt',
    description: 'auf jede Bestellung',
  },
  {
    id: 'free_shipping',
    title: 'Kostenloser Standardversand',
    description: '',
  },
  {
    id: 'customer_support',
    title: 'Kundenservice',
    description: '',
  },
  {
    id: 'special_offers',
    title: 'Zugang zu Sonderaktionen',
  },
  {
    id: 'supply_chain_management',
    title: 'Supply Chain Management',
  },
  {
    id: 'personal_account_manager',
    title: 'Persönlicher Ansprechpartner',
  },
  {
    id: 'exclusive_events',
    title: 'Zugang zu exklusiven Events',
  },
];
