<div class="w-100 d-flex flex-column" *ngIf="progress$ | async as progress">
  <packex-progress-bar [progress]="progress.percent"
                       class="mt-3 w-75 align-self-center"></packex-progress-bar>

  <p class="fw-bold mt-2 mb-0 d-flex justify-content-center">
    {{'INVENTORY.PRINT_DATA_UPLOAD.DATA_CHECK_IN_PROGRESS' | transloco}}
  </p>
  <p class="w-100 d-flex align-self-center justify-content-center mb-4">
    {{'INVENTORY.PRINT_DATA_UPLOAD.CAN_TAKE_A_WHILE' | transloco}}
  </p>

  <packex-progress-stepper [steps]="stepperSteps"
                           [currentStep]="progress.currentStep || 0"></packex-progress-stepper>
</div>
