<div class="inviteBanner">
  <div class="content">
    <div class="title">{{ 'LOYALTY.PAGE.REFERRAL.TITLE' | transloco }}</div>
    <div class="inviteBanner__description">
      <p [innerHTML]="'LOYALTY.PAGE.REFERRAL.DESCRIPTION' | transloco">
        Sammle Punkte, indem du PackEx weiter empfiehlst! Für jeden neu
        registrierten Kunden, <strong>erhältst du 1.000 Punkte</strong>, sobald
        dieser die erste Bestellung getätigt hat.
      </p>
    </div>
    <div class="inviteBanner__cta">
      <packex-button
        icon="add_circle"
        type="primary"
        background="primary-dark"
        (buttonClick)="inviteCustomers()"
        class="w-auto"
        >{{ 'LOYALTY.PAGE.REFERRAL.BUTTON' | transloco }}</packex-button
      >
    </div>
  </div>
</div>
