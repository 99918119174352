import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '@env';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { applicationConfig } from '@app/application.config';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://3df1d60bbeab44538dc35001c2bdcfbb@o1162107.ingest.sentry.io/4504575422627840',
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      Sentry.replayIntegration({
        block: ['iframe'],
      }),
    ],

    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment.environment,
    release: 'portal@' + environment.version,
  });
}

registerLocaleData(localeDe);

bootstrapApplication(AppComponent, applicationConfig).catch((err) =>
  console.error(err),
);
