import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { PrintData } from '@shared/models/cart-order';
import { InventoryItem } from '@shared/models/inventory-item';
import { Variant } from '@shared/models/variant';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  filter,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { translate } from '@ngneat/transloco';

export type UploadState = 'upload' | 'uploading' | 'showResult';

@Component({
  selector: 'packex-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent {
  @ViewChild('fileUploader') fileUploaderRef!: ElementRef<HTMLInputElement>;

  @Input() acceptedFileTypes = 'pdf';
  @Input() acceptedFileExtensions: string[] = ['pdf'];
  @Input() description = '';

  @Input() inventoryItem?: InventoryItem;
  @Input() uploadObject!: Variant | PrintData | null;
  @Input() sizeMinInMb = 0;
  @Input() sizeMaxInMb = 70;
  @Input() disabled = false;

  @Input() set fileIsUploading(value: boolean | null) {
    if (value) {
      this.state$.next('uploading');
    } else {
      this.state$.next('upload');
    }
  }

  @Output() fileUploaded = new EventEmitter<FormData>();
  @Output() fileUploadFinish = new EventEmitter();
  /**
   * dragging in progress
   */
  isDragOver = false;
  filename?: string;

  public uploadObject$ = new BehaviorSubject<Variant | PrintData | null>(null);
  error = new BehaviorSubject<string>('');
  state$ = new BehaviorSubject<UploadState>('showResult');

  constructor(private readonly snackbarService: SnackBarService) {}

  ngOnChanges() {
    if (this.uploadObject) {
      this.uploadObject$.next(this.uploadObject);
      this.state$.next('uploading');
    }
  }

  /**
   * set the file via file selection
   * @param event
   */
  onFileSelected(event: Event): void {
    if (!this.disabled && event.target) {
      const files = (event.target as HTMLInputElement).files || [];
      this.setFile(files[0]);
    }
  }

  /**
   * Setzt den Dateinamen und übermittelt die Datei an die Parent-Komponente
   * @param file
   */
  private setFile(file: File): void {
    if (file) {
      this.checkFile(file)
        .pipe(
          catchError((error: string) => {
            this.filename = '';
            this.error.next(error);
            return of(error);
          }),
        )
        .subscribe(async (response: File | string) => {
          if (typeof response !== 'string') {
            this.error.next('');
            this.filename = file.name;
            await this.uploadFile(file);
          }
        });
    }
  }

  private checkFile(file: File): Observable<File | string> {
    let error = null;
    if (this.sizeMinInMb && file.size < this.sizeMinInMb * 1024 * 1024) {
      error = 'FILE_UPLOAD.FILE_TO_SMALL';
    } else if (this.sizeMaxInMb && file.size > this.sizeMaxInMb * 1024 * 1024) {
      error = 'FILE_UPLOAD.FILE_TO_BIG';
    }

    if (!this.checkFileExtension(file.name)) {
      error = 'FILE_UPLOAD.WRONG_FORMAT';
    }

    if (error) {
      const err = translate(error, { fileSize: 100 });
      console.log(err);
      return throwError(err);
    }

    return of(file);
  }

  private checkFileExtension(fileName: string): boolean {
    if (this.acceptedFileExtensions) {
      return this.acceptedFileExtensions.some((suffix) => {
        return fileName.toLowerCase().endsWith(`.${suffix}`);
      });
    }
    return true;
  }

  /**
   * prevent default browser behaviour on dragging a file
   * @param event
   */
  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  /**
   * prevent default browser behaviour on dragover a file
   * @param event
   */
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    this.isDragOver = false;
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer?.files[0];
    if (file) this.setFile(file);
  }

  public chooseFile() {
    this.fileUploaderRef.nativeElement.click();
  }

  private async uploadFile(file: File) {
    this.state$.next('uploading');

    const formData = new FormData();
    formData.set('name', this.filename || 'filename.pdf');
    formData.append('artwork', file);

    this.fileUploaded.next(formData);
  }

  public showUploadProcess(): Observable<boolean> {
    return this.uploadObject$.pipe(
      combineLatestWith(
        this.state$.pipe(
          filter((state) => state === 'uploading'),
          map(() => true),
        ),
      ),
      map(([uploadObject, stateIsUploading]) => {
        return !!uploadObject && stateIsUploading;
      }),
    );
  }

  public uploadErrorOccurred(): void {
    this.snackbarService.showSimpleError(
      'INVENTORY.PRINT_DATA_UPLOAD.FATAL_ERROR',
    );
    this.showResult();
  }

  public showResult() {
    this.fileUploadFinish.next(true);
  }
}
