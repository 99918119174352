// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    url: 'https://api.packex.growpony.com', // "http://localhost:3000"
    version: '1',
  },
  useBrowserLang: false,
  defaultLang: 'de',
  keycloak: {
    url: 'https://id.packex.com',
    realm: 'packex-stage',
    clientId: 'portal',
  },
  version: null,
  GTM_ID: 'GTM-NG88H2K',
  GTM_AUTH: 'JdmmgfaW4IAduFjbB-9Y_g',
  GTM_PREVIEW: 'env-11',
  environment: 'development',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
