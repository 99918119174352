<div [ngStyle]="{ width: this.width}">
  <h5 *ngIf="headline">{{ headline | transloco }}</h5>
  <div class="input-container" >
    <input
        #inputField
        class="input-field"
        [ngClass]="{ 'error': error }"
        type="number"
        [attr.data-testid]="'configurator-field-'+ field + '-input'"
        [(ngModel)]="value"
        [min]="min"
        [max]="max"
        autocomplete="off"
        (input)="onChange()"
        (change)="onChange()"
        (focus)="onFocus()"
        (keydown)="onInputKeyDown($event)"
        (blur)="onBlur()"

    />
    <span class="unit-hint" *ngIf="unit">{{ unit }}</span>
  </div>
  <span class="range-hint" *ngIf="min && max">{{ min }} - {{ max }} {{ unit }}</span>
  <span class="error-hint" *ngIf="error">{{ error | transloco }}</span>
</div>
