<packex-card class="w-100 mb-4">
  <div class="overview">
    <h4>{{ 'LOYALTY.PAGE.OVERVIEW.TITLE' | transloco }}</h4>
    <p>{{ 'LOYALTY.PAGE.OVERVIEW.DESCRIPTION' | transloco }}</p>
    <div class="statusInfoContainer">
      @for (loyaltyStatus of statuses; track loyaltyStatus.id) {
      <packex-club-status-info
        [status]="loyaltyStatus"
        [active]="isCurrentStatus(loyaltyStatus)"
      >
      </packex-club-status-info>
      }
    </div>
  </div>
</packex-card>
