import { Component, input } from '@angular/core';
import { ProgressBarComponent } from '@modules/club/components/progress-bar/progress-bar.component';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal.pipe';
import { LoyaltyStatusDto } from '@modules/club/loyalty-club.types';
import { TranslocoPipe } from '@jsverse/transloco';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'packex-status-banner',
  styleUrls: ['./status-banner.component.scss'],
  templateUrl: './status-banner.component.html',
  imports: [
    ProgressBarComponent,
    FormatDecimalPipe,
    TranslocoPipe,
    FormatDatePipe,
    NgOptimizedImage,
  ],
})
export class StatusBannerComponent {
  status = input<LoyaltyStatusDto | null>();

  get nextLevelPercentage() {
    if (!this.status()?.nextStatus) {
      return 0;
    }
    return this.status()!.points / this.status()!.nextStatus!.minPoints;
  }

  get nextLevelPercentageWithPending() {
    if (!this.status()?.nextStatus) {
      return 0;
    }
    return (this.status()!.points + this.status()!.pendingPoints) / this.status()!.nextStatus!.minPoints;
  }
}
