import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'packex-scaled-img',
  templateUrl: './scaled-img.component.html',
  styleUrls: ['./scaled-img.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScaledImgComponent {
  @Input() src!: string;
  @Input() width!: number;
  @Input() height!: number;

  get imageUrl(): string {
    let src = this.src;
    if (!src.startsWith('http')) {
      src = `${window.location.protocol}//${window.location.host}/${src}`;
    }
    return `https://img.packex.com/img/dpr:${
      window.devicePixelRatio ?? 2
    }/rs:fit:${this.width}:${this.height}:0/plain/${src}`;
  }
}
