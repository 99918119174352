<packex-modal  (onDismiss)="dismiss()">
  <h5 class="modal-title">{{ 'CART.ADDITIONAL_INFO_REQUIRED' | transloco }}</h5>

  <div class="modal-body">
    <form [formGroup]="form">
      <input matInput type="text"
             formControlName="vatId" *ngIf="form.controls['vatId']"
             class="my-3 w-100"
             [placeholder]="('ADDRESS.VATID' | transloco) + '*'"/>

      <packex-recipients (recipientsChanged)="recipientsChanged($event)"
                         placeholder="CART.BILLING.RECIPIENT_PLACEHOLDER"
                         *ngIf="form.controls['invoiceRecipientEmails']"></packex-recipients>
    </form>
  </div>

  <packex-button type="primary" [disabled]="!form.valid" (buttonClick)="onConfirm()">{{ 'COMMON.SAVE_AND_CONTINUE' | transloco }}</packex-button>
</packex-modal>
