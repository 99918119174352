import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { REDIRECT_LOCALSTORAGE_KEY } from '@shared/guards/guards.helper';
import { filter, map, Observable, of } from 'rxjs';
import { UserService } from '@app/core/services/user.service';
import { User } from '@shared/models/user';

/**
 * RedirectService
 *
 * Main purpose of this guard's Service is to redirect a user to some page.
 *
 * At some parts of the application a redirect will be saved to REDIRECT_LOCALSTORAGE_KEY.
 *
 * If the user navigates to another page than a USER Route he should be redirected to the route that is saved in
 * REDIRECT_LOCALSTORAGE_KEY once
 *
 */
@Injectable({
  providedIn: 'root',
})
class RedirectService {
  readonly storage = window.localStorage;

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const redirect = this.storage.getItem(REDIRECT_LOCALSTORAGE_KEY);

    if (redirect && state.url === redirect) {
      return of(true);
    } else if (redirect) {
      return this.userService.user$.pipe(
        filter((user: User | null) => !!user),
        map((user: User | null) => {
          if (user && user.isEmailVerified && this.userService.userCompleted) {
            this.router
              .navigateByUrl(redirect)
              .then(() => this.storage.removeItem(REDIRECT_LOCALSTORAGE_KEY));
            return false;
          }
          return true;
        }),
      );
    }

    return of(true);
  }
}

export const RedirectGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(RedirectService).canActivate(route, state);
};
