import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/services/api.service';
import { PagedResult } from '@shared/models/paged-result';
import {
  LoyaltyStatus,
  LoyaltyStatusDto,
  LoyaltyTransaction,
} from '@modules/club/loyalty-club.types';
import { PageQuery } from '@app/shared/models/page-meta';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyClubService {
  constructor(private readonly apiService: ApiService) {}

  /**
   * Calls the API to get the transactions
   * @param param0 Page query paramaters
   * @returns
   */
  getTransactions({
    page = 1,
    limit = 10,
  }: PageQuery): Observable<PagedResult<LoyaltyTransaction>> {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.toString());
    searchParams.append('limit', limit.toString());
    searchParams.append('sort', 'createdAt');
    searchParams.append('order', 'desc');

    return this.apiService.get(`loyalty?${searchParams.toString()}`);
  }

  /**
   * Calls the API to get the loyalty status
   * @returns The loyalty status
   */
  getStatus(): Observable<LoyaltyStatusDto> {
    return this.apiService.get('loyalty/status');
  }

  getAvailableLevels(): Observable<LoyaltyStatus[]> {
    return this.apiService.get('loyalty/levels');
  }

  /**
   * Calls the API to refer a customer
   * @param email - The email of the customer to refer
   * @returns
   */
  referCustomer(email: string) {
    return this.apiService.post('loyalty/refer', { email });
  }
}
