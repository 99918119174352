import { Component, ViewEncapsulation } from '@angular/core';
import { RequestOfferModalComponent } from '@modules/constructions/components/request-offer-modal/request-offer-modal.component';
import { CONSTRUCTION } from '@modules/constructions/pages/constructions-page/constructions-page.component';
import { translate, TranslocoPipe } from '@jsverse/transloco';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { HeaderLanguageComponent } from '../header-language/header-language.component';
import { ProductsFoldoutComponent } from '../products-foldout/products-foldout.component';
import { RoutingDataUtilityService } from '@app/core/services/routing-data-utility.service';
import { DrawerService } from '@shared/services/drawer.service';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { UserService } from '@app/core/services/user.service';
import { CartService } from '@modules/cart/cart.service';
import { DeactivateGuard } from '@shared/guards/deactivate.guard';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from '@app/core/services/language.service';
import { BaseHeaderComponent } from '@app/core/components/header/base-header.component';

@Component({
  selector: 'packex-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgIf,
    RouterLink,
    IconComponent,
    HeaderLanguageComponent,
    ProductsFoldoutComponent,
    TranslocoPipe,
  ],
})
export class MobileMenuComponent extends BaseHeaderComponent {
  constructor(
    router: Router,
    route: ActivatedRoute,
    routingDataUtility: RoutingDataUtilityService,
    drawerService: DrawerService,
    breakpointService: BreakpointObserverService,
    userService: UserService,
    cartService: CartService,
    deactivateGuard: DeactivateGuard,
    dialog: MatDialog,
    languageService: LanguageService,
  ) {
    super(
      router,
      route,
      routingDataUtility,
      drawerService,
      breakpointService,
      userService,
      cartService,
      deactivateGuard,
      dialog,
      languageService,
    );
  }

  public openMusterboxLink(): void {
    window.open(translate('EXIT_INTENT.SAMPLE_BOX_LINK'), '_blank');
  }

  public openRequestConstructionModal(): void {
    this.dialog.open(RequestOfferModalComponent, {
      data: {
        type: CONSTRUCTION,
      },
    });
  }
}
