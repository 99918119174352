import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from '@app/routes';

@Component({
    selector: 'packex-home-redirect',
    template: '',
    
})
export class HomeRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams; // Get query parameters
    this.router.navigate(['/', ROUTES.CONSTRUCTIONS], { queryParams }); // Redirect and preserve query params
  }
}
