import { Component, ViewEncapsulation } from '@angular/core';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { FilterCategory } from '@modules/filter/models/filter-category';
import { FilterService } from '@modules/filter/services/filter.service';

@Component({
  selector: 'packex-construction-filter-categories',
  templateUrl: './construction-filter-categories.component.html',
  styleUrls: ['./construction-filter-categories.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConstructionFilterCategoriesComponent {
  constructor(
    public readonly filterService: FilterService,
    public breakpointObserverService: BreakpointObserverService,
  ) {}

  public isCategoryOpen(category: FilterCategory): boolean {
    return this.breakpointObserverService.isMobile() ? true : category.active;
  }
}
