import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '@app/core/services/product.service';
import { ROUTES } from '@app/routes';
import { Product } from '@app/shared/models/product';
import { scrollIntoViewWithOffset } from '@app/utils/scroll.helper';
import { RequestOfferModalComponent } from '@modules/constructions/components/request-offer-modal/request-offer-modal.component';
import { FilterCategory } from '@modules/filter/models/filter-category';
import { FilterService } from '@modules/filter/services/filter.service';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OnboardingDialogService } from '@shared/components/onboarding-dialog/onboarding-dialog.service';
import { ConstructionType } from '@shared/models/construction';
import { PagedResult } from '@shared/models/paged-result';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { PreviousRouteService } from '@shared/services/previous-route.service';

export type ConstructionCard = Partial<Product> & {
  linkText?: string;
  color?: string;
  bgColor?: string;
  positions?: number[];

  detailsOpen: boolean;
  isMarketingCard: boolean;
};

export const INLAY = 'inlay';
export const CONSTRUCTION = 'construction';
export const OTHER = 'other';

@UntilDestroy()
@Component({
  selector: 'app-constructions',
  templateUrl: './constructions-page.component.html',
  styleUrls: ['./constructions-page.component.scss'],
})
export class ConstructionsPageComponent implements OnInit {
  @ViewChild('categoriesHeadline') categoriesHeadline!: ElementRef;

  marketingCards: ConstructionCard[] = [
    {
      id: 'sample',
      imageUrl: '/assets/images/marketing-cards/sample.jpg',
      description: 'MARKETING_CARDS.SAMPLE.DESCRIPTION',
      shortDescription: `MARKETING_CARDS.SAMPLE.SHORT_DESCRIPTION`,
      linkText: 'MARKETING_CARDS.SAMPLE.BUTTON',
      color: 'primary-dark',
      bgColor: '#B1A5D1',
      positions: [2],
      isMarketingCard: true,
      detailsOpen: false,
      summary: '',
    },
    {
      id: CONSTRUCTION,
      imageUrl: '/assets/images/marketing-cards/soko.png',
      description: 'MARKETING_CARDS.SOKO.DESCRIPTION',
      shortDescription: 'MARKETING_CARDS.SOKO.SHORT_DESCRIPTION',
      linkText: 'MARKETING_CARDS.SOKO.BUTTON',
      color: 'primary-dark',
      bgColor: '#91DAEE',
      positions: [8],
      isMarketingCard: true,
      detailsOpen: false,
      summary: '',
    },
    {
      id: INLAY,
      imageUrl: '/assets/images/marketing-cards/inlay.jpg',
      description: 'MARKETING_CARDS.INLAY.DESCRIPTION',
      shortDescription: 'MARKETING_CARDS.INLAY.SHORT_DESCRIPTION',
      linkText: 'MARKETING_CARDS.INLAY.BUTTON',
      color: 'primary-dark',
      bgColor: '#82B6C5',
      positions: [14],
      isMarketingCard: true,
      detailsOpen: false,
      summary: '',
    },
  ];

  items?: ConstructionCard[];

  carouselSlides = [
    {
      asset: 'assets/images/marketing/marketing-etiketten.png',
      bg: '#AEBEBC',
      title: 'MARKETING.LABEL.TITLE',
      content: 'MARKETING.LABEL.CONTENT',
      buttonText: 'MARKETING.LABEL.BUTTON',
      callback: async () => {
        await this.filterService.addMarketingFilter(ConstructionType.Label);
      },
    },
    {
      asset: 'assets/images/marketing/marketing-shipping-box.png',
      bg: '#DBEDF7',
      title: 'MARKETING.SHIPPING_BOX.TITLE',
      content: 'MARKETING.SHIPPING_BOX.CONTENT',
      buttonText: 'MARKETING.SHIPPING_BOX.BUTTON',
      callback: async () => {
        await this.filterService.addMarketingFilter(
          ConstructionType.ShippingBox,
        );
      },
    },
    {
      asset: 'assets/images/marketing/welcome.png',
      bg: '#58a8be',
      title: 'MARKETING.WELCOME.TITLE',
      content: 'MARKETING.WELCOME.CONTENT',
      buttonText: 'MARKETING.WELCOME.BUTTON',
      callback: () => {
        this.scrollToCategories();
      },
    },
  ];

  constructor(
    private readonly filterService: FilterService,
    private readonly productService: ProductService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly onboardingService: OnboardingDialogService,
    private readonly gtm: GoogleTagService,
    private readonly previousRouteService: PreviousRouteService,
  ) {}

  ngOnInit() {
    this.checkPreviousRoute();

    this.gtm.push('view_item_list');

    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe(({ type, filter }) => {
        if (type && filter) {
          const filters: string[] = filter.split(',');

          filters.forEach((filter) => {
            this.filterService.addFilter({
              name: `PRODUCT_FILTER.${type.toUpperCase()}.${filter}`,
              active: false,
              type,
            });
          });

          setTimeout(() => {
            this.scrollToCategories();
          }, 1);
        }
      });

    this.filterService.filterCategories$
      .pipe(untilDestroyed(this))
      .subscribe((filter: FilterCategory[]) => {
        if (filter && filter.length) {
          this.load();
        }
      });

    this.onboardingService.open();

    if (this.filterService.selectedFilters.length) {
      setTimeout(() => {
        this.scrollToCategories();
      }, 1);
    } else {
      document.body.scrollIntoView();
    }
  }

  private load() {
    this.productService
      .findAll()
      .subscribe((products: PagedResult<Product>) => {
        this.items = products.items.map((construction: Product) => ({
          ...construction,
          detailsOpen: false,
          isMarketingCard: false,
        }));

        this.marketingCards.forEach((marketingCard) =>
          marketingCard.positions?.forEach((idx) =>
            this.items?.splice(idx - 1, 0, marketingCard),
          ),
        );
      });
  }

  public onDetailsOpen(construction: ConstructionCard): void {
    if (this.items) {
      this.items = this.items.map((item) => {
        return item.isMarketingCard
          ? item
          : {
              ...item,
              detailsOpen: item.id === construction.id,
            };
      });
    }
  }

  public marketingCardClicked(item: ConstructionCard): void {
    switch (item.id) {
      case 'sample':
        window.open(translate('EXIT_INTENT.SAMPLE_BOX_LINK'), '_blank');
        return;
      case INLAY:
      case CONSTRUCTION:
        this.dialog.open(RequestOfferModalComponent, {
          data: {
            type: item.id,
          },
        });
        return;
      default:
        return;
    }
  }

  public scrollToCategories(): void {
    scrollIntoViewWithOffset(this.categoriesHeadline.nativeElement, 70);
  }

  private checkPreviousRoute() {
    if (
      !this.previousRouteService.getPreviousUrl.includes(
        `/${ROUTES.CONFIGURATOR}`,
      )
    ) {
      this.filterService.removeAllFilters();
    }
  }
}
