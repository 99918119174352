import { Component, EnvironmentInjector, OnInit, signal } from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import {
  PaginationChangedEvent,
  PaginationComponent,
} from '@shared/components/ui/pagination/pagination.component';
import { LoyaltyClubService } from '@modules/club/loyalty-club.service';
import { formatDate } from '@app/utils/format';
import { TranslocoPipe } from '@jsverse/transloco';
import { PagedResult } from '@shared/models/paged-result';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal.pipe';
import { LoyaltyTransaction } from '@modules/club/loyalty-club.types';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';

@Component({
  selector: 'packex-club-transactions-list',
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatTable,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    MatHeaderCellDef,
    PaginationComponent,
    TranslocoPipe,
    FormatDecimalPipe,
    NgxPaginationModule,
    FormatDatePipe,
  ],
  templateUrl: './club-transactions-list.component.html',
  styleUrl: './club-transactions-list.component.scss',
})
export class ClubTransactionsListComponent implements OnInit {
  transactions = signal<PagedResult<LoyaltyTransaction> | null>(null);
  currentPage = 1;
  itemsPerPage: number = PaginationComponent.getDefaultItemsPerPage();

  constructor(
    public injector: EnvironmentInjector,
    private readonly loyaltyClubService: LoyaltyClubService,
  ) {}

  ngOnInit(): void {
    this.loadTransactions(this.currentPage);
  }

  /**
   * Load status information from the server
   */
  loadTransactions(page: number): void {
    this.loyaltyClubService
      .getTransactions({ page, limit: this.itemsPerPage })
      .subscribe((data) => {
        this.transactions.set(data);
      });
  }

  onPageChanged(event: PaginationChangedEvent): void {
    this.currentPage = event.page;
    this.itemsPerPage = event.itemsPerPage;
    this.loadTransactions(this.currentPage);
  }

  get totalItems(): number {
    return this.transactions()?.page.totalItems ?? 0;
  }

  displayedColumns: string[] = [
    'description',
    'createdAt',
    'expiresAt',
    'status',
    'points',
  ];
  protected readonly formatDate = formatDate;
}
