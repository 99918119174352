import { Component, Input } from '@angular/core';

@Component({
  selector: 'packex-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss'],
})
export class FilterCardComponent {
  @Input() image = '';
  @Input() title = '';
  @Input() active = false;
  @Input() backgroundColor: string | undefined;

  @Input() firstCard = false;
}
