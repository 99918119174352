import {
  Component,
  HostBinding,
  input,
  ViewEncapsulation,
} from '@angular/core';
import {
  LOYALTY_FEATURES,
  LoyaltyFeatureStatus,
  LoyaltyStatusDto,
} from '@modules/club/loyalty-club.types';
import { translate } from '@jsverse/transloco';
import { ButtonComponent } from '@app/shared/components/ui/button/button.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestOfferModalComponent } from '@app/modules/constructions/components/request-offer-modal/request-offer-modal.component';
import { CONSTRUCTION } from '@app/modules/constructions/pages/constructions-page/constructions-page.component';

export interface Feature {
  id: string;
  title: string;
  description?: string;
  credits?: number;
  remainingCredits?: number;
}

@Component({
  selector: 'packex-club-feature',
  templateUrl: './club-feature.component.html',
  styleUrl: './club-feature.component.scss',
  imports: [ButtonComponent],
})
export class ClubFeatureComponent {
  status = input<LoyaltyStatusDto | null>();
  feature = input<LoyaltyFeatureStatus>();

  constructor(private readonly dialog: MatDialog) {}

  openCADialog() {
    this.dialog.open(RequestOfferModalComponent, {
      data: {
        type: CONSTRUCTION,
        redeemCADVoucher: true,
      },
    });
  }

  @HostBinding('class.disabled')
  get disabled() {
    return this.feature()?.remaining === 0;
  }

  get title() {
    const discountValue = this.status()?.discount ?? 0;
    const discount =
      discountValue <= 0
        ? translate('LOYALTY.FEATURES.discount.individual')
        : (this.status()?.discount ?? 0) * 100.0 + '%';
    return translate('LOYALTY.FEATURES.' + this.feature()?.id + '.title', {
      discount,
    }).replace(/-/, '-<br/>');
  }

  get description() {
    return translate('LOYALTY.FEATURES.' + this.feature()?.id + '.description');
  }

  get remainingCredits() {
    return this.feature()?.remaining ?? null;
  }

  get totalCredits() {
    return this.feature()?.total ?? null;
  }
}
