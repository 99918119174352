import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OrderService } from '@app/core/services/order.service';
import { xmlResponseToSafeSvgUrl } from '@app/utils/svgXMLToSvgUrl';
import { OrderItem } from '@shared/models/cart-order';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'packex-order-item-dieline-svg',
  templateUrl: './order-item-dieline-svg.component.html',
  styleUrls: ['./order-item-dieline-svg.component.scss'],
})
export class OrderItemDielineSvgComponent implements OnChanges {
  @Input() orderItem?: OrderItem;
  imageSvg$: Observable<SafeResourceUrl> = of('');

  constructor(
    private readonly orderService: OrderService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  ngOnChanges() {
    if (this.orderItem) {
      this.imageSvg$ = this.orderService.getDielineSvg(this.orderItem).pipe(
        map((response) => {
          return this.sanitizer.bypassSecurityTrustResourceUrl(
            xmlResponseToSafeSvgUrl(response),
          );
        }),
      );
    }
  }
}
