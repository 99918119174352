import { Component, Input } from '@angular/core';
import { IconColor } from '@shared/components/icon/icon.component';

@Component({
  selector: 'packex-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
  @Input() label = '';
  @Input() icon = 'edit';
  @Input() iconColor: IconColor = 'white';
}
