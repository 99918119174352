import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { buildEventSource } from '@app/utils/eventSource.helper';
import { Step } from '@shared/components/progress-stepper/progress-stepper.component';
import { PrintData } from '@shared/models/cart-order';
import { Variant } from '@shared/models/variant';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'packex-file-upload-processing',
  templateUrl: './file-upload-processing.component.html',
})
export class FileUploadProcessingComponent implements OnChanges, OnDestroy {
  @Input() uploadObject: Variant | PrintData | null = null;
  @Output() fileCheckFinished = new EventEmitter();
  @Output() onError = new EventEmitter();

  public eventSource?: EventSource;
  progress$ = new BehaviorSubject({ percent: 0, currentStep: 0 });

  stepperSteps: Step[] = [
    {
      label: 'INVENTORY.PRINT_DATA_UPLOAD.STEP_UPLOAD',
    },
    {
      label: 'INVENTORY.PRINT_DATA_UPLOAD.STEP_CHECK',
    },
    {
      label: 'INVENTORY.PRINT_DATA_UPLOAD.STEP_RESULT',
    },
  ];

  constructor(private cdr: ChangeDetectorRef, private zone: NgZone) {}

  ngOnChanges(): void {
    if (this.uploadObject && this.uploadObject.status !== 'complete') {
      this.eventSource = buildEventSource(
        `${ApiService.apiUrl}/status-updates/subscribe/${this.uploadObject.id}`,
      );

      this.eventSource.onmessage = ({ data }) => {
        if (data.status === 'error') {
          this.closeStream();
          this.onError.emit();
        } else {
          const parsedData = JSON.parse(data);
          const percent = parsedData.progress * 100;

          this.updateProgress(percent);
        }
      };
    } else {
      this.fileCheckFinished.next(true);
    }
  }

  ngOnDestroy(): void {
    this.closeStream();
  }

  private updateProgress(percent: number) {
    this.zone.run(() => {
      this.progress$.next({
        currentStep: 1,
        percent,
      });

      if (percent === 100) {
        this.progress$.next({
          currentStep: 2,
          percent,
        });

        setTimeout(() => {
          this.closeStream();
          this.fileCheckFinished.next(true);
        }, 1500);
      }
      this.cdr.detectChanges();
    });
  }

  private closeStream(): void {
    this.eventSource?.close();
  }
}
