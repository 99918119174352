<h4 class="mb-3">{{ 'INVENTORY.PRINT_DATA_UPLOAD.HEADLINE' | transloco}}</h4>

<p>{{ 'INVENTORY.PRINT_DATA_UPLOAD.DESCRIPTION' | transloco}}</p>

<div class="my-3 drag-area"
     [class.droparea]="isDragOver"
     [class.dragarea]="!isDragOver"
     (dragenter)="onDragEnter($event)"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)"
     (drop)="onDrop($event)"
     [class.active]="!!filename"
     [ngClass]="{ disabled: disabled }"
>
  <div class="description d-flex flex-column" *ngIf="(state$ | async) === 'upload'">
    <packex-icon icon="upload" class="d-flex justify-content-center mb-2" size="l" color="secondary"></packex-icon>
    <p class="mb-0">{{'FILE_UPLOAD.DESCRIPTION' | transloco}}</p>
    <a class="choose-file-button text-secondary-medium"
       (click)="chooseFile()">{{'FILE_UPLOAD.CHOOSE_FILE' | transloco}}</a><br/>
    <ng-container *ngIf="error | async">
      <p class="subtitle-2 text-error">{{ error | async | transloco }}</p>
      <p class="text-error">{{ 'FILE_UPLOAD.UPLOAD_CORRECT_FORMAT' | transloco }}</p>
    </ng-container>
    <input
      class="file-input"
      #fileUploader
      [disabled]="disabled"
      [accept]="acceptedFileTypes"
      type="file"
      [attr.data-testid]="'file-upload'"
      (change)="onFileSelected($event)"
    />
  </div>
  <packex-progress-spinner *ngIf="!uploadObject && (state$ | async) === 'uploading'"></packex-progress-spinner>
  <packex-file-upload-processing
    *ngIf="showUploadProcess() | async"
    [uploadObject]="uploadObject"
    (onError)="uploadErrorOccurred()"
    (fileCheckFinished)="showResult()"></packex-file-upload-processing>
</div>
