import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import {
  createInterceptorCondition,
  INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
  IncludeBearerTokenCondition,
  includeBearerTokenInterceptor,
} from 'keycloak-angular';
import { providePackExKeycloak } from '@app/utils/keycloak-helper';
import { provideTransloco } from '@jsverse/transloco';
import {
  AVAILABLE_LANGUAGES,
  LanguageService,
} from '@app/core/services/language.service';
import { TranslocoHttpLoader } from '@app/transloco-root.module';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideRouter } from '@angular/router';
import appRoutes from '@app/app-routing.module';
import { UserService } from '@app/core/services/user.service';
import { CartService } from '@modules/cart/cart.service';
import { DrawerService } from '@shared/services/drawer.service';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { DeactivateGuard } from '@shared/guards/deactivate.guard';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { environment } from '@env';
import { HttpErrorInterceptor } from '@app/core/interceptors/http-error.interceptor';

const urlCondition = createInterceptorCondition<IncludeBearerTokenCondition>({
  urlPattern: /(localhost|packex\.com)/i,
  bearerPrefix: 'Bearer',
});

export const applicationConfig = {
  providers: [
    { provide: 'googleTagManagerId', useValue: environment.GTM_ID },
    { provide: 'googleTagManagerAuth', useValue: environment.GTM_AUTH },
    { provide: 'googleTagManagerPreview', useValue: environment.GTM_PREVIEW },
    providePackExKeycloak(),
    {
      provide: INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
      useValue: [urlCondition],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [SnackBarService],
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideTransloco({
      config: {
        availableLangs: [...AVAILABLE_LANGUAGES],
        defaultLang: environment.defaultLang,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          logMissingKey: false,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),
    UserService,
    CartService,
    DrawerService,
    LanguageService,
    BreakpointObserverService,
    DeactivateGuard,
    SnackBarService,
    provideAnimations(),
    provideHttpClient(
      withInterceptors([includeBearerTokenInterceptor]),
      withInterceptorsFromDi(),
    ),
  ],
} as ApplicationConfig;
