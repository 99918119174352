import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@app/core/services/user.service';
import { ExitIntentModalComponent } from '@shared/components/exit-intent-modal/exit-intent-modal.component';
import { now } from 'lodash';
import * as moment from 'moment';

export type EXIT_INTENT_SESSION_KEY =
  | 'exit-intent-configurator'
  | 'exit-intent-constructions';

const SECONDS_TO_WAIT_FOR_TRIGGER = 30;

@Injectable({
  providedIn: 'root',
})
export class ExitIntentModalService {
  sessionsStart = now();
  constructor(
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
  ) {}

  public openIntentModal(key: EXIT_INTENT_SESSION_KEY): void {
    const shown = localStorage.getItem(key);

    const timePassed = moment().diff(this.sessionsStart, 'second');

    if (
      !this.userService.user &&
      !shown &&
      timePassed > SECONDS_TO_WAIT_FOR_TRIGGER
    ) {
      if (!this.dialog.openDialogs.length) {
        this.dialog
          .open(ExitIntentModalComponent)
          .afterOpened()
          .subscribe(() => {
            localStorage.setItem(key, 'true');
          });
      }
    }
  }
}
