<mat-sidenav-container class="drawer" autosize (backdropClick)="drawerService.close()">
  <mat-sidenav
      (keydown)="onKeyDown($event)"
      (click)="clicked()"
      fixedInViewport
      mode="side"
      position="end"
      #flyout
      class="mat-elevation-z3 drawer-sidenav">

    <ng-container *ngIf="(cart$ | async) as cart">
      <mat-toolbar class="position-fixed justify-content-between">
        <span>{{ 'CART.CART' | transloco }} {{ cart.items.length }} {{ 'CART.ITEMS' | transloco }}</span>

        <packex-button class="close-btn" (click)="drawerService.close()" type="clear-icon"
                       [attr.data-testid]="'cart-flyout-close'">
          <packex-icon icon="close"></packex-icon>
        </packex-button>
      </mat-toolbar>

      <mat-sidenav-content class="m-0 pt-6 cart-flyout-content d-flex flex-column">
        <div class="border-top border-grey-medium mx-4" *ngIf="(cartProducts$ | async)?.length === 0"
             [attr.data-testid]="'cart-flyout'">
          <packex-empty-screen class="d-block product-wrapper m-auto mt-9"
                               [config]="emptyScreenConfig"
                               (onButtonClick)="goToMyProducts()"></packex-empty-screen>
        </div>
        <div class="product-wrapper" *ngIf="(cartProducts$ | async)?.length" [attr.data-testid]="'cart-flyout'">
          <packex-button (buttonClick)="deleteAllProducts()" [attr.data-testid]="'delete-all-products-button'"
                         style="visibility: hidden; height: 1px; width: 1px;" class="d-flex">Delete all Products
          </packex-button>
          <packex-cart-flyout-product [product]="product" *ngFor="let product of cartProducts$ | async"
                                      [reducedView]="true"
                                      class="border-top border-light d-block py-4"></packex-cart-flyout-product>
        </div>

        <div class="price-overview" *ngIf="(cartProducts$ | async)?.length">
          <div class="d-flex justify-content-between border-bottom border-light py-3 mt-2">
            <p class="text-primary-dark mb-0">{{ 'CART.DELIVERY.SHIPPING_COUNTRY' | transloco }}</p>
            <span class="subtitle-3 text-primary-dark">{{ deliveryCountry }}</span>
          </div>
          <packex-cart-voucher></packex-cart-voucher>

          <div class=" d-flex justify-content-between pt-2">
            <h5 class="text-primary-dark">{{ 'CART.TOTAL' | transloco }}</h5>
            <h5 class="text-primary-dark">{{ cart.netPrice | formatPrice }}</h5>
          </div>
          <span class="caption">{{ 'CONFIGURATION.PRICE.INCLUDING_PRINT_AND_SHIPPING' | transloco }}</span>
          <span class="caption text-primary-medium">{{ 'CART.PLUS_TAX' | transloco }}</span>
          <packex-button class="mt-3 mb-4" [attr.data-testid]="'flyout-cart-button'"
                         (buttonClick)="goToCartPage()">{{ 'CART.TO_CART_PAGE' | transloco }}
          </packex-button>
        </div>
      </mat-sidenav-content>
    </ng-container>
  </mat-sidenav>
</mat-sidenav-container>
