import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { NgClass } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';
import { HeaderLanguageComponent } from '../header-language/header-language.component';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [NgClass, MatToolbar, HeaderLanguageComponent, IconComponent, TranslocoPipe]
})
export class FooterComponent implements OnInit {
  public currentYear: number = new Date().getFullYear();
  public visible = false;

  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe(() => {
        this.visible = false;
      });

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.visible = true;
      });
  }
}
