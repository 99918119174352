import { Component } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'packex-exit-intent-modal',
  templateUrl: './exit-intent-modal.component.html',
})
export class ExitIntentModalComponent extends ModalComponent {
  public openMusterboxLink(): void {
    window.open(translate('EXIT_INTENT.SAMPLE_BOX_LINK'), '_blank');
    this.dismiss();
  }
}
