import { Routes } from '@angular/router';
import { NotFoundComponent } from '@app/core/components/not-found/not-found.component';
import { UserCompletedGuard } from '@app/core/guards/user-completed.guard';
import { ROUTES } from '@app/routes';
import { RedirectToChangePasswordComponent } from '@modules/user/components/redirect-to-change-password.component';
import { VerifyEmailComponent } from '@modules/user/components/verify-email-component/verify-email-component';
import { RedirectGuard } from '@shared/guards/redirectGuard';

import { HomeRedirectComponent } from '@app/home-redirect.component';
import { LoyalityClubPageComponent } from '@modules/club/loyality-club-page.component';
import { AuthGuard } from '@app/core/guards/auth.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeRedirectComponent,
    pathMatch: 'full',
  },
  {
    path: ROUTES.LOYALTY,
    component: LoyalityClubPageComponent,
  },
  {
    path: ROUTES.CART,
    loadChildren: () => import('./modules/cart/cart-routing.module'),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: ROUTES.CONSTRUCTIONS,
    loadChildren: () =>
      import('./modules/constructions/constructions-routing.module'),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: ROUTES.INVENTORY,
    loadChildren: () => import('./modules/inventory/inventory-routing.module'),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: ROUTES.USER,
    loadChildren: () => import('./modules/user/user-routing.module'),
  },
  {
    path: ROUTES.ORDERS,
    loadChildren: () => import('./modules/orders/orders.routing.module'),
    canActivate: [UserCompletedGuard, RedirectGuard],
  },
  {
    path: 'users/reset-password/:token',
    component: RedirectToChangePasswordComponent,
  },
  {
    path: 'users/verify/:token',
    component: VerifyEmailComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  { path: ROUTES.NOT_FOUND, component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

export default appRoutes;
