<div [class]="['statusContainer', status()?.currentStatus?.id]">
  <img
    ngSrc="/assets/images/rewards.svg"
    alt="Rewards Logo"
    class="rewards-logo"
    height="28"
    width="28"
  />
  <div class="currentStatus">
    {{ 'LOYALTY.HEROES.' + status()?.currentStatus?.id | transloco }}
  </div>
  <div class="pointsContainer">
    <div class="currentPoints">
      <span class="title">{{ 'LOYALTY.PAGE.CURRENT_POINTS' | transloco }}</span
      ><br />
      {{ status()?.points | formatDecimal }} {{ 'LOYALTY.POINTS' | transloco }}
    </div>
    @if (status()?.nextStatus) {
    <div class="nextPoints">
      {{ 'LOYALTY.HEROES.' + status()?.nextStatus?.id | transloco }}
      <br />{{ status()?.nextStatus?.minPoints | formatDecimal }}
    </div>
    }
  </div>
  @if (status()?.nextStatus) {
  <div>
    <packex-progress-bar
      [value]="nextLevelPercentage"
      [pendingValue]="nextLevelPercentageWithPending"
    ></packex-progress-bar>
  </div>
  }
  <div class="hint">
    @if ((status()?.pendingPoints ?? 0) > 0) {
    {{
      'LOYALTY.PAGE.PENDING_POINTS_INFO'
        | transloco : { pendingPoints: status()?.pendingPoints | formatDecimal }
    }}
    }@if ((status()?.expiringPoints ?? 0) > 0) {
    {{
      'LOYALTY.PAGE.EXPIRATIO_INFO'
        | transloco
          : { expirationPoints: status()?.expiringPoints | formatDecimal }
    }}
    }@if(status()?.currentStatus?.isDefault === false){
    {{
      'LOYALTY.PAGE.KEEP_STATUS_INFO'
        | transloco
          : {
              status: status()?.currentStatus?.name,
              expiresAt: status()?.currentStatus?.expiresAt | formatDate,
              minPoints: status()?.currentStatus?.minPoints | formatDecimal
            }
    }}
    }
  </div>
</div>
