<header id="packex-header" class="d-flex box-shadow align-items-center packex-toolbar">
  <div class="packex-container">
    <mat-toolbar>
      <button *ngIf="showMenu" class="menu-icon d-lg-none">
        <packex-icon icon="burger" (click)="toggleMobileMenu()"></packex-icon>
      </button>
      <button *ngIf="!showMenu" class="menu-icon d-lg-none">
        <packex-icon icon="close" (click)="closeAllFoldouts()"></packex-icon>
      </button>
      <div [ngClass]="{'d-flex justify-content-center w-100': !showHeader, 'ps-3 ps-md-4 pe-3 pe-xl-10': showHeader}">
        <a [routerLink]="link" [ngClass]="{'ms-0 ms-md-20': !showHeader}">
          <img src="assets/images/packex-logo.png" title="PackEx GmbH" alt="PackEx GmbH Logo"
               class="packex-logo"></a>
      </div>

      <div *ngIf="showHeader" class="navigation-container">
        <nav id="menu-main" class="d-none d-lg-block">
          <ul class="d-flex">
            <li class="d-flex me-4 align-items-center" (focus)="openProductsFoldout()" (mouseover)="openProductsFoldout()">
              <a routerLink="constructions" routerLinkActive="text-secondary-medium"
                 [routerLinkActiveOptions]="routerActiveMatchOptions"
                 class="d-flex button-navigation text-primary-dark pe-0">{{ 'DEFAULT.MAIN_NAVIGATION.PRODUCTS' | transloco }}
              </a>
              <packex-button [icon]="headerElementsVisible.product ? 'up' : 'down'"
                             (buttonClick)="toggleProductsFoldout()"
                             class="ms-1" iconSize="s"
                             type="clear-icon"></packex-button>
            </li>
            <li class="d-flex align-items-center"><a routerLink="inventory" routerLinkActive="text-secondary-medium"
                                                     [routerLinkActiveOptions]="routerActiveMatchOptions"
                                                     class="button-navigation text-primary-dark">{{ 'DEFAULT.MAIN_NAVIGATION.MY_PRODUCTS' | transloco }}</a>
            </li>
            <li class="d-flex align-items-center"><a routerLink="orders" routerLinkActive="text-secondary-medium"
                                                     [routerLinkActiveOptions]="routerActiveMatchOptions"
                                                     class="button-navigation text-primary-dark">{{ 'DEFAULT.MAIN_NAVIGATION.MY_ORDERS' | transloco }}</a>
            </li>
          </ul>
        </nav>
      </div>
      <div *ngIf="showHeader && !loggedIn" class="d-flex justify-content-between align-items-center">
        <a
            class="d-none d-md-block button-navigation text-primary-dark text-decoration-none text-uppercase cursor-pointer"
            (click)="login()">{{ 'COMMON.LOGIN' | transloco }}</a>
        <packex-button (buttonClick)="register()" class="ms-4">{{ 'COMMON.REGISTER' | transloco }}</packex-button>
      </div>

      <div *ngIf="showHeader && loggedIn" class="d-flex justify-content-between align-items-center">
        <packex-button icon="subtract_circle" *ngIf="isUserImpersonated" type="clear-icon"
                       (buttonClick)="unImpersonate()"
                       [matTooltip]="'Change back to your user'"></packex-button>
        <div class="d-flex mx-4">
          <packex-button icon="cart" type="clear-icon" (buttonClick)="openCartFlyout()"
                         [attr.data-testid]="'open-cart-flyout-button'">
          </packex-button>
          <span class="circle bg-secondary-medium text-white cart-items-count caption"
                *ngIf="numberOfProductsInCart$ | async">{{ numberOfProductsInCart$ | async }}</span>
        </div>

        <div class="foldout_item">
          <packex-header-notification (click)="toggleNotificationFoldout()" class="me-4"></packex-header-notification>
          <packex-notification-foldout *ngIf="headerElementsVisible.notifications"
                                       (close)="patchElementVisibility({notifications: false})"></packex-notification-foldout>
        </div>

        <div class="foldout_item">
          <packex-user-info [user]="user | async" [onlyInitials]="true"
                            class="cursor-pointer"
                            style="position:relative"
                            [attr.data-testid]="'user-info'"
                            (mouseover)="openUserFoldout()"
                            (click)="toggleUserFoldout()">

          </packex-user-info>
          <packex-user-foldout *ngIf="headerElementsVisible.user" [user]="user | async"
                               (close)="patchElementVisibility({user: false})"></packex-user-foldout>
        </div>
      </div>
      <div
          class="header-additional d-none d-md-flex ms-4 align-items-center justify-content-center border-start border-grey-medium">
        <packex-header-language class="mx-4"></packex-header-language>
      </div>
    </mat-toolbar>


  </div>
  <packex-products-foldout *ngIf="headerElementsVisible.product" (filterClicked)="onFilterClicked()"
                           (close)="patchElementVisibility({product: false})"></packex-products-foldout>

  <packex-mobile-menu *ngIf="headerElementsVisible.menu"></packex-mobile-menu>
</header>
