import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from '@app/routes';
import { USER_PASSWORD_ROUTES } from '@modules/user/user-routing.module';

@Component({
    selector: 'packex-redirect-to-change-password',
    template: '',
    
})
export class RedirectToChangePasswordComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.params.subscribe((params) =>
      this._onRouteGetParams(params['token']),
    );
  }

  private _onRouteGetParams(token: string) {
    this.router.navigateByUrl(
      `${ROUTES['USER']}/${USER_PASSWORD_ROUTES.CHANGE}/${token}`,
    );
  }
}
