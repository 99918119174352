import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CartService } from '@modules/cart/cart.service';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { take } from 'rxjs';

@Component({
  selector: 'packex-cart-additional-informations-modal',
  templateUrl: './cart-additional-informations-modal.component.html',
  styleUrls: ['./cart-additional-informations-modal.component.scss'],
})
export class CartAdditionalInformationsModalComponent extends ModalComponent {
  form: FormGroup = new FormGroup({});

  constructor(
    dialogRef: MatDialogRef<CartAdditionalInformationsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      equalsDeliveryAddress: boolean;
      invoiceRecipientEmails: string[];
    },
    private readonly cartService: CartService,
  ) {
    super(dialogRef);

    this.init();
  }

  public init(): void {
    this.cartService.cart.pipe(take(1)).subscribe((cart) => {
      if (this.dialogData.equalsDeliveryAddress) {
        const deliveryAddress = cart.deliveryAddress;

        if (!deliveryAddress?.vatId) {
          this.form.addControl(
            'vatId',
            new FormControl('', Validators.required),
          );
        }
      }

      if (
        !cart.invoiceRecipientEmails.length &&
        !this.dialogData.invoiceRecipientEmails.length
      ) {
        this.form.addControl(
          'invoiceRecipientEmails',
          new FormControl([], Validators.required),
        );
      }
    });
  }

  public recipientsChanged($event: string[]): void {
    this.form.controls['invoiceRecipientEmails'].patchValue($event);
  }

  override onConfirm(): void {
    this.dialogRef.close(this.form.value);
  }
}
