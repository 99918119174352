import { ElementRef } from '@angular/core';
import { isNumberInput } from '@app/utils/format';

export const handleInputKeyDown = (
  event: any,
  input: ElementRef,
  allowNumbersOnly = true,
) => {
  if (event.keyCode === 13 && input !== undefined) {
    input.nativeElement.blur();
    return;
  }

  if (allowNumbersOnly) {
    if (
      event.ctrlKey ||
      event.metaKey ||
      ['ArrowLeft', 'ArrowRight', '.', ','].includes(event.key)
    ) {
      return true;
    }
    return isNumberInput(event);
  }
  return true;
};
