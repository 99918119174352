import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserNotification } from '@shared/models/notification';
import { filter } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { NgIf } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';
import { UserService } from '@app/core/services/user.service';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'packex-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss'],
  imports: [MatTooltip, ButtonComponent, NgIf, TranslocoPipe],
})
export class HeaderNotificationComponent implements OnInit {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly usersService: UserService,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event: Event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.notificationService.getUserNotifications();
      });
  }

  get notifications(): UserNotification[] {
    return this.notificationService.userNotifications$.getValue();
  }

  get badgeCount(): number {
    return this.notifications.filter(
      (notification) =>
        notification.requiresUserAction ||
        !this.usersService.user?.notificationsLastReadAt ||
        moment(this.usersService.user?.notificationsLastReadAt).isBefore(
          notification.createdAt,
        ),
    ).length;
  }
}
