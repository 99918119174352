<packex-modal (onDismiss)="dismiss()" [showButtonsInRow]="true">

  <h5 class="modal-title">
    <packex-icon icon="filter" class="me-2"></packex-icon>
    {{ 'FILTER.HEADLINE' | transloco }}<span
    class="circle circle-small subtitle-2 bg-secondary-light ms-3">{{ filterService.selectedFilters.length }}</span></h5>

  <div class="modal-body">
    <packex-construction-filter-categories>
    </packex-construction-filter-categories>
  </div>

  <packex-button icon="refresh" type="tertiary" (buttonClick)="filterService.removeAllFilters()"
                 buttonPadding="p-0"
                 class="w-auto"
                 color="primary-dark">
    {{ 'FILTER.RESET' | transloco }}
  </packex-button>

  <packex-button (click)="onConfirm()" class="w-auto ms-auto">{{'FILTER.APPLY' | transloco}}</packex-button>
</packex-modal>
