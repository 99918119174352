import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { translate } from '@jsverse/transloco';
import { SnackBarProperties } from '@shared/components/snackbar/snackbar.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { inject } from '@angular/core';
import Keycloak from 'keycloak-js';

const DEFAULT_ERROR_MESSAGE = 'ERROR.UNKNOWN';

export class HttpErrorInterceptor implements HttpInterceptor {
  private keycloak = inject(Keycloak);

  constructor(private snackbarService: SnackBarService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.handle403(request, next);
          return throwError(() => error);
        }

        const showSnackbar = ![400, 404, 409, 500].includes(error.status);
        const snackbarData = {
          icon: 'error',
          buttons: [
            {
              label: 'COMMON.CLOSE',
              cb: () => this.snackbarService.dismiss(),
            },
          ],
          message: DEFAULT_ERROR_MESSAGE,
        } as SnackBarProperties;

        if (showSnackbar) {
          if (error.status === 0) {
            this.snackbarService.showError({
              ...snackbarData,
              rawMessage: translate('COMMON.NO_INTERNET'),
            });
          } else if (error.message && error.status !== 500) {
            this.snackbarService.show({
              ...snackbarData,
              rawMessage: error.message,
            });
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private handle403(request: HttpRequest<any>, next: HttpHandler) {
    if (this.keycloak.isTokenExpired(20)) {
      return from(
        this.keycloak.updateToken(20).catch(() => this.keycloak.logout()),
      ).pipe(switchMap(() => next.handle(request)));
    } else {
      this.keycloak.logout();
      return;
    }
  }
}
