import { Inject, Injectable } from '@angular/core';
import { GoogleEvents } from '@shared/models/google-events';
import { CartOrder } from '@shared/models/cart-order';
import { ModalCart } from '@shared/models/cart';
import { PaymentMethod } from '@shared/models/paymentMethod';
import { WINDOW } from '@shared/window.token';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagService {
  constructor(
    @Inject(WINDOW)
    private readonly window: Window,
  ) {
    window.dataLayer = window.dataLayer || [];
  }

  public async push(event: GoogleEvents, params?: { [k: string]: string }) {
    try {
      this.window.dataLayer.push({ ...params, event: 'packex.' + event });
    } catch (e) {
      console.error(`error occurred for pushing event: packex.${event}`, e);
    }
  }

  public async purchase(order: CartOrder) {
    try {
      const items = order.orderItems
        .map((item) =>
          [
            {
              item_id: item.references.constructionId,
              item_name: item.constructionName,
              price: item.unitPrice,
              item_category: item.orderItemCategory,
              quantity: item.amount,
              service_count: item.children.length,
              packex_order_item_number: item.orderItemNumber,
            },
            item.children.map((item) => ({
              item_id: item.references.productOptionId,
              item_name: item.name,
              item_category: 'service',
              price: item.unitPrice,
              quantity: item.amount,
            })),
          ].flat(),
        )
        .flat();

      const hashBuffer = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(order.orderNumber),
      );
      const transactionIdHex = Array.from(new Uint8Array(hashBuffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');

      const params = {
        ecommerce: {
          transaction_id: transactionIdHex,
          packex_order_number: order.orderNumber,
          value: order.totalNetPrice,
          currency: 'EUR',
          items,
        },
      };

      this.window.dataLayer.push({ ...params, event: 'packex.purchase' });
    } catch (e) {
      console.error(`error occurred for pushing event: packex.purchase`, e);
    }
  }

  public async addPaymentMethod(paymentMethod: PaymentMethod) {
    try {
      const params = {
        ecommerce: {
          currency: 'EUR',
          items: [
            {
              item_id: paymentMethod.id,
              item_name: paymentMethod.name,
              item_category: 'payment',
              quantity: 1,
            },
          ],
        },
      };

      this.window.dataLayer.push({
        ...params,
        event: 'packex.add_payment_method',
      });
    } catch (e) {
      console.error(
        `error occurred for pushing event: packex.add_payment_method`,
        e,
      );
    }
  }

  public async addToCart(cart: ModalCart) {
    try {
      const params = {
        ecommerce: {
          value: cart.items
            .reduce((acc, curr) => acc + (curr.price ?? 0), 0)
            .toString(),
          currency: 'EUR',
          items: cart.items.map((item) => ({
            item_id: item.variant?.inventoryItem.construction?.id,
            item_name:
              item.variant?.inventoryItem.construction?.name ?? 'Unknown',
            price: item.unitPrice,
            service_count: item.productOptions?.length ?? 0,
            item_category:
              item.variant?.inventoryItem.construction?.category ?? 'Unknown',
            quantity: item.configuration.amount,
          })),
        },
      };

      this.window.dataLayer.push({ ...params, event: 'packex.add_to_cart' });
    } catch (e) {
      console.error(`error occurred for pushing event: packex.${event}`, e);
    }
  }

  public async setUserId(user_id: string | null) {
    try {
      this.window.dataLayer.push({ user_id });
    } catch (e) {
      console.error(`error occurred for setting user id: ${user_id}`, e);
    }
  }
}
