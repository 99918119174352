<packex-modal (onDismiss)="dismiss()">
  <h5 class="modal-title">{{ 'EXIT_INTENT.TITLE' | transloco }}</h5>

  <div class="modal-body d-flex justify-content-center flex-column">
    <img src="assets/images/exit_intent_image.png" class="w-100  h-auto m-auto mb-3" alt="Samplebox"/>
    <p class="text-primary-medium" [innerHTML]="'EXIT_INTENT.BODY' | transloco"></p>
  </div>

  <packex-button class="w-auto" (click)="openMusterboxLink()">{{ 'EXIT_INTENT.BUTTON' | transloco }}</packex-button>
</packex-modal>
