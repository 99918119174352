import { Component, forwardRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { LanguageService } from '@app/core/services/language.service';
import { RoutingDataUtilityService } from '@app/core/services/routing-data-utility.service';
import { UserService } from '@app/core/services/user.service';
import { CartService } from '@modules/cart/cart.service';
import { DeactivateGuard } from '@shared/guards/deactivate.guard';
import { DrawerService } from '@shared/services/drawer.service';
import { MatToolbar } from '@angular/material/toolbar';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { HeaderNotificationComponent } from '../header-notification/header-notification.component';
import { UserInfoComponent } from '@shared/components/ui/user-info/user-info.component';
import { HeaderLanguageComponent } from '../header-language/header-language.component';
import { ProductsFoldoutComponent } from '../products-foldout/products-foldout.component';
import { NotificationFoldoutComponent } from '../notification-foldout/notification-foldout.component';
import { UserFoldoutComponent } from '../user-foldout/user-foldout.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MobileMenuComponent } from '@app/core/components/mobile-menu/mobile-menu.component';
import { BaseHeaderComponent } from '@app/core/components/header/base-header.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    MatToolbar,
    NgIf,
    IconComponent,
    NgClass,
    RouterLink,
    RouterLinkActive,
    ButtonComponent,
    MatTooltip,
    HeaderNotificationComponent,
    UserInfoComponent,
    HeaderLanguageComponent,
    ProductsFoldoutComponent,
    NotificationFoldoutComponent,
    UserFoldoutComponent,
    AsyncPipe,
    TranslocoPipe,
    forwardRef(() => MobileMenuComponent),
  ],
  providers: [MatDialog],
})
export class HeaderComponent extends BaseHeaderComponent {
  constructor(
    router: Router,
    route: ActivatedRoute,
    routingDataUtility: RoutingDataUtilityService,
    drawerService: DrawerService,
    breakpointService: BreakpointObserverService,
    userService: UserService,
    cartService: CartService,
    deactivateGuard: DeactivateGuard,
    dialog: MatDialog,
    languageService: LanguageService,
  ) {
    super(
      router,
      route,
      routingDataUtility,
      drawerService,
      breakpointService,
      userService,
      cartService,
      deactivateGuard,
      dialog,
      languageService,
    );
  }
}
