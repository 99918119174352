import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { roundToHalf } from '@app/utils/format';
import { handleInputKeyDown } from '@app/utils/input.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Configuration } from '@shared/models/configuration';
import { debounceTime, Observable, Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements OnInit {
  @Input() min = 0;
  @Input() max = 99999;
  @Input() step = 1;
  @Input() unit = 'mm';
  @Input() headline = '';
  @Input() value = 0;
  @Input() allowNumbersOnly = false;
  @Input() error = '';
  @Input() disabled = false;

  @Input() field!: keyof Configuration;
  @Input() configurationUpdated!: Observable<Configuration | null>;

  @ViewChild('sliderInput') sliderInput!: ElementRef;

  @Output() valueChanged = new EventEmitter<number>();
  debouncer: Subject<number> = new Subject<number>();

  ngOnInit(): void {
    this.value = this.value === 0 ? this.min : this.value;
    this.debouncer
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((value) => this.valueChanged.emit(value));

    this.configurationUpdated
      .pipe(untilDestroyed(this))
      .subscribe((configuration: Configuration | null) => {
        if (
          configuration &&
          this.value !== (configuration[this.field] as number)
        ) {
          this.value = configuration[this.field] as number;
        }
      });
  }

  public add(): void {
    let newValue = Math.fround(this.value) + Math.fround(this.step);
    if (newValue > this.max) newValue = this.max;
    this.value = newValue;
    this.emit();
  }

  private emit(): void {
    this.disabled = true;
    this.debouncer.next(this.value);
  }

  public substract(): void {
    let newValue = this.value - this.step;
    if (newValue < this.min) newValue = this.min;
    this.value = newValue;
    this.emit();
  }

  public onInputKeyDown(event: any) {
    return handleInputKeyDown(event, this.sliderInput, this.allowNumbersOnly);
  }

  public onInputBlur(): void {
    if (this.step === 1) {
      this.setValue(Math.round(Number(this.value)));
    } else {
      this.setValue(roundToHalf(String(this.value)));
    }
  }

  public onChange(event: Event): void {
    this.setValue(Number((event.target as HTMLInputElement).value) || this.min);
  }

  private setValue(value: number): void {
    this.error = '';

    this.value = value;

    if (this.value < this.min) {
      this.error = `CONFIGURATION.ERRORS.${this.field.toUpperCase()}_FALL_BELOW`;
    } else if (this.value > this.max) {
      this.error = `CONFIGURATION.ERRORS.${this.field.toUpperCase()}_EXCEEDS`;
    }

    this.emit();
  }
}
