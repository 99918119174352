import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartItemComponent } from '@modules/cart/cart-item/cart-item.component';
import { CartService } from '@modules/cart/cart.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { NgIf } from '@angular/common';
import { DownloadButtonComponent } from '../../../shared/components/ui/download-button/download-button.component';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { FormatPricePipe } from '@shared/pipes/format-price.pipe';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-cart-flyout-item',
    templateUrl: './cart-flyout-item.component.html',
    styleUrls: ['../cart-item/cart-item.component.scss'],
    imports: [NgIf, DownloadButtonComponent, ButtonComponent, FormatPricePipe, TranslocoPipe]
})
export class CartFlyoutItemComponent extends CartItemComponent {
  constructor(
    dialog: MatDialog,
    cartService: CartService,
    gtm: GoogleTagService,
  ) {
    super(dialog, cartService, gtm);
  }
}
