import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LANGUAGE, LanguageService } from '@app/core/services/language.service';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ModalComponent as ModalComponent_1 } from '../modal/modal.component';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';
import { ButtonComponent } from '../ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-language-select-modal',
    templateUrl: './language-select-modal.component.html',
    imports: [ModalComponent_1, MatRadioGroup, FormsModule, NgFor, MatRadioButton, ButtonComponent, TranslocoPipe]
})
export class LanguageSelectModalComponent extends ModalComponent {
  public currentLanguage: LANGUAGE;
  constructor(
    dialogRef: MatDialogRef<LanguageSelectModalComponent>,
    public readonly languageService: LanguageService,
  ) {
    super(dialogRef);

    this.currentLanguage = this.languageService.currentLanguage();
  }

  public save() {
    this.languageService.switchLanguage(this.currentLanguage);

    this.dismiss();
  }
}
